import axios from 'axios'
import config from '../config'

const RECEIVE_MISSIONS = 'RECEIVE_MISSIONS'
const RECEIVE_WINNERS = 'RECEIVE_WINNERS'
const SEND_TASK = 'SEND_TASK'
const SEND_ENTRY = 'SEND_ENTRY'
const UPDATE_STATUS = 'UPDATE_STATUS'

const initialState = {
  drawEntered: null,
  missions: [],
  winners: {},
  totalScore: 0,
  eligible: null,
  collected: false,
}


export default function missionReducer(state = initialState, action) {
  switch (action.type) {

    case RECEIVE_MISSIONS:
      return {
        ...state,
        drawEntered: action.entered,
        totalScore: action.totalScore,
        missions: action.missions
      }
    case RECEIVE_WINNERS:
      return {
        ...state,
        winners: action.winners
      }
    case SEND_TASK:
      return {
        ...state,
        totalScore: action.totalScore,
        collected: action.collected
      }
    case UPDATE_STATUS:
      return {
        ...state,
        collected: action.collected
      }
    case SEND_ENTRY:
      return {
        ...state,
        drawEntered: action.entered,
        eligible: action.eligible,
      }
    default:
      return state
  }
}

export function getMissions() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/missions`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(receiveMissions(data.missions, data.total_score, data.draw_entered))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function completeTask(data) {
  return (dispatch) => {
    return axios
      .post(`${config.apiUrl}/api/v1/user_missions`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(recordTask(data.total_points, data.success))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function enterDraw(user_id) {
  return (dispatch) => {
    return axios
      .post(`${config.apiUrl}/api/v1/lucky_draw_entries/${user_id}/enter`, {}, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ status, data }) => {
        if (status === 200 && data.success) {
          dispatch(luckyDrawEntry(data.draw_entered, data.eligible))
        }
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

export function getWinners() {
  return (dispatch) => {
    return axios
      .get(`${config.apiUrl}/api/v1/lucky_draw_entries/results`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
        },
      })
      .then(({ data }) => {
        dispatch(receiveWinners(data))
      })
      .catch((err) => {
        console.error('Error: ', err)
      })
  }
}

function receiveMissions(missions, totalScore, entered) {
  return {
    type: RECEIVE_MISSIONS,
    entered,
    totalScore,
    missions,
  }
}
export function updateCollected(collected) {
  return {
    type: UPDATE_STATUS,
    collected,
  }
}
function recordTask(totalScore, collected) {
  return {
    type: SEND_TASK,
    totalScore,
    collected,
  }
}
function luckyDrawEntry(entered, eligible) {
  return {
    type: SEND_ENTRY,
    entered,
    eligible,
  }
}
function receiveWinners(winners) {
  return {
    type: RECEIVE_WINNERS,
    winners,
  }
}
