import React from 'react'
import { Modal } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'
import { CoinComponent } from '../Gamification/CoinComponent'

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid #5c628a;
    border-radius: 28px;
    padding:8px;
  }
  .modal-header {
    border: none;
  }
  h6 {
    color: ${COLORS.primary};
  }
  .file-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    button {
      background: transparent;
      border: none;
      color: ${COLORS.primaryLight};
    }
  }
  .modal-content-button {
    background: #5461a2;
    color: white;
    font-size: 12px;
    border: none;
    border-radius: 20px;
    padding: 5px 20px;
    text-decoration: none;
  }
`

export default function DownloadModal({ files, downloadOpen, setDownloadOpen, mission }) {
  return (
    <StyledModal
      show={downloadOpen}
      onHide={()=>setDownloadOpen(false)}
      aria-labelledby="download-modal"
      centered
    >
      { mission && <CoinComponent mission={mission} />}
      <Modal.Header closeButton>
        <h6 id="download-modal">Select file(s) to preview</h6>
      </Modal.Header>
      <Modal.Body>
        {
          files.map(file=>(
            <div key={file.filename} className="file-container">
              <p className="m-0">{file.filename}</p>
              <div>
                <a href={file.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="modal-content-button"
                >
                  View
                </a>
              </div>
            </div>
          ))
        }
      </Modal.Body>
    </StyledModal>
  )
}
