import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'
import { COLORS, SIZES } from '../../styles/theme'
import config from '../../config'
import banner from '../../images/registration/bannerL.png'
import bannerM from '../../images/registration/bannerP.png'
import checkCircle from '../../images/registration/check_circle.png'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.register.landscape});
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  color: #FFFFFF;
  padding-bottom: 4.5em;
  padding-top: 3em;
  img#banner-portrait {
    display: none;
  }
  
  #message {
    border: 3px solid #FEE15C;
    border-radius: 12px;
    width: 65%;
    margin: 0 auto;
    padding: 1.5rem 2rem;
    text-align: center;
    h5 {
      font-weight: 500;
      font-size: 20px;
    }
  }
  .mobile-show {
    display: none;
  }
  ${SIZES.mobile} {
    padding: 1em 0;
    background-image: url(${config.assets.register.portrait});
    .mobile-hide {
      display: none;
    }
    .mobile-show {
      display: block;
    }
    img#banner-portrait {
      display: block;
    }
    #message {
      font-size: 0.75em;
      padding: 1.5rem 1rem;
      width: 85%;
    }
    .col-md-7 {
      flex: none;
      max-width: 100%
    }
  }
`

export default function PostRegisterPage() {

  return (
    <Page>
      <Row noGutters className="px-4 pt-5 pb-3">
        <div>
          <img id="banner-portrait" width="100%" src={bannerM} alt="event-banner" className="mx-auto my-2" />
        </div>
        <Col md={5} className="align-self-center mt-4 mt-md-0 mobile-hide">
          <img id="banner" width="80%" src={banner} alt="event-banner" className="mx-auto d-block" />
        </Col>
        <Col md={7} className="align-self-center">
          <div id="message" className="mt-5">
            <img width="100px" src={checkCircle} alt="check-icon" className="d-block mx-auto mb-3" />
            <h5>
              Thank you for your registration and you will receive a confirmation email soon.
            </h5>
          </div>
        </Col>
      </Row>
    </Page>
  )
}
