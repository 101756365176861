import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../../styles/theme'
import hintIcon from '../../images/gamification/hint.png'

const Wrapper = styled.div`
  position: fixed;
  top: 8.5%;
  left: 5%;
  cursor: pointer;
  color: #F85D49;
  font-weight: 500;
  span {
    transition: 100ms ease;
    opacity: 1;
    font-size: 0;
    margin-left: 8px;
  }
  &:hover {
    span {
      font-size: 20px;
      opacity: 1;
    }
  }
  ${SIZES.mobile} {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    position: static;
    span {
      opacity: 1;
      font-size: 14px;
      transition: 100ms ease;
      margin: 0 8px;
    }
  }
`

function HintComponent ({ showMission, handleShowHint, missionName }) {
  return (
    <Wrapper onClick={handleShowHint}>
      <img src={hintIcon} width="55px" alt="hint"/>
      {showMission ? <small className="mx-2 ml-md-3 font-weight-bold">{missionName} Hint</small> : <span>{missionName} Hint</span>}
    </Wrapper>
  )
}
export { HintComponent }
