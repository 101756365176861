import React from 'react'
import styled from 'styled-components'
import { Modal, Carousel } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import { CoinComponent } from '../Gamification/CoinComponent'

const StyledModal = styled(Modal)`
  .modal-content {
    border: solid 2px #5c628a;
    border-radius: 28px;
    padding:8px;
    max-width: 75%;
    margin: 0 auto;
  }
`
const StyledCarousel = styled(Carousel)`
  .carousel-control-next, 
  .carousel-control-prev {
    opacity: 1;
  }
  .carousel-control-prev {
    left: -6%;
  } 
  .carousel-control-next {
    right: -6%;
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    background-color: ${COLORS.primary};
    padding: 12px;
    border: 4px solid ${COLORS.primary};
    border-radius: 4px;
  }
`
export default function PosterModal({ images, posterOpen, setPosterOpen, mission }) {
  const onClickImage = (url) => window.open(url, '_blank');
  return (

    <StyledModal
      show={posterOpen}
      onHide={()=>setPosterOpen(false)}
      aria-labelledby="images-modal"
      centered
      size="xl"
    > 
      { mission && <CoinComponent mission={mission} />}
      <Modal.Body>
        {
          images.length > 0 ? (
            images.length > 1 ?
            <StyledCarousel>
              {
                images.map((poster,index)=>(
                  <Carousel.Item key={`poster-${index}`} interval={4000}>
                    <img
                      className="d-block w-100"
                      src={poster.url}
                      alt={`poster-${index}`}
                      onClick={() => onClickImage(poster.url)}
                    />
                  </Carousel.Item>
                ))
              }
            </StyledCarousel>
            :
            <img
              className="d-block w-100"
              src={images[0].url}
              alt="poster"
            />
          ) :
          <h6 className="text-secondary text-center m-0">No poster uploaded for this booth.</h6>
        }
      </Modal.Body>
    </StyledModal>
  )
}
