import Axios from 'axios'
import queryString from 'query-string'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useHistory, useLocation } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import config from '../../config'
import routes from '../../routes'
import { COLORS } from '../../styles/theme'

const Page = styled.div`
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-image: url(${config.assets.entrance.landscape});
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;

  .reset-form {
    position: fixed;
    bottom: 5vh;
    right: 3vw;
    padding: 4vw;
    padding-bottom: 0;
    max-height: 100%;
    text-align: center;
    color: white;
    h4 {
      color: #404040;
    }
    form {
      background: #fff;
      padding: 30px 10px;
      border-radius: 28px;
      margin: auto;
      margin-top: 30px;
      max-width: 400px;

      .form-input {
        width: 80%;
        background: ${COLORS.lightGray};
        border: none;
        padding: 10px 20px;
        border-radius: 25px;
        margin-top: 20px;

        &::placeholder {
          color: ${COLORS.lightGray1};
        }
      }
    }
  }
  a,
  button.submit-btn {
    color: #ffffff;
    width: 80%;
    font-weight: 500;
    border-radius: 56px;
    display: flex;
    justify-content: space-between;
    padding: 4px 30px;
    margin: auto;
    margin-top: 3em;
    margin-bottom: 1em;
    font-size: 1em;
    outline: none;
    background: #ce2440;
    border: 2px solid #fff;
    box-shadow: 0px 5px 4px -4px #000;
    font-size: 12px;

    &:disabled {
      cursor: not-allowed;
      background-color: grey;
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function SetPasswordFormPage() {
  const location = useLocation()
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)

    Axios.post(
      `${config.apiUrl}/api/v1/create_password`,
      {
        password,
      },
      {
        headers: {
          Authorization: `Bearer ${queryString.parse(location.search).token}`,
        },
      }
    )
      .then(() => {
        setLoading(false)
        toast('Your password is successfully created!')
        history.push(routes.root)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response?.data?.message)
      })
  }

  const hasEmptyField = !(password.length && passwordConfirmation.length && password === passwordConfirmation)

  return (
    <Page>
      <div className="reset-form">
        <form onSubmit={handleSubmit}>
          <h4>Create Password</h4>
          <input className="form-input" required type="password" value={password} placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
          <input
            className="form-input"
            required
            type="password"
            placeholder="Password Confirmation"
            value={passwordConfirmation}
            onChange={(e) => setPasswordConfirmation(e.target.value)}
          />
          <div>
            {password && passwordConfirmation && hasEmptyField && (
              <p className="text-danger text-center">
                <em>
                  <small>Please make sure both password matches</small>
                </em>
              </p>
            )}
            <Button type="submit" className="submit-btn" variant="default" disabled={hasEmptyField || loading} onSubmit={handleSubmit}>
              <div>{loading ? 'Loading...' : 'CREATE PASSWORD'}</div>
              <div className="arrow">⇀</div>
            </Button>
          </div>
        </form>
      </div>
    </Page>
  )
}
