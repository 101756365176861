import thb1 from '../images/gallerium/thumbnails/1.png'
import thb2 from '../images/gallerium/thumbnails/2.png'
import thb3 from '../images/gallerium/thumbnails/3.png'
import thb4 from '../images/gallerium/thumbnails/4.png'
import thb5 from '../images/gallerium/thumbnails/5.png'
import thb6 from '../images/gallerium/thumbnails/6.png'
import thb7 from '../images/gallerium/thumbnails/7.png'
import thb8 from '../images/gallerium/thumbnails/8.png'
import thb9 from '../images/gallerium/thumbnails/9.png'
import thb10 from '../images/gallerium/thumbnails/10.png'
import thb11 from '../images/gallerium/thumbnails/11.png'
import thb12 from '../images/gallerium/thumbnails/12.png'
import thb13 from '../images/gallerium/thumbnails/13.png'
import thb14 from '../images/gallerium/thumbnails/14.png'
import playback from '../images/gallerium/thumbnails/playback.png'


export default [
  {
    id : 1,
    booth: "Ensure Max Protein",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/mg0dijn6ow8i7hv947xrsye9oqnc',
    image : thb1,
  },
  {
    id : 2,
    booth: "Ensure Max Protein",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/j8ns701zwrx6m8naonf530xpt57d',
    image : thb2,
  },
  {
    id : 3,
    booth: "Glucerna",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/j4kj43hnu7zihzu792ei8kp6s0ni',
    image : thb3,
  },
  {
    id : 4,
    booth: "Glucerna",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/blz0goi4ldox7e67y06cjgcg5obg',
    image : thb4,
  },
  {
    id : 5,
    booth: "Glucerna",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/l50yoxli4ecljr5fo1xz4qamm267',
    image : thb5,
  },
  {
    id : 6,
    booth: "Glucerna",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/72ssbddm8gmbufxxi4rkfpup03wp',
    image : thb6,
  },
  {
    id : 7,
    booth: "Glucerna",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/lv4she2iuavxwkrka5i0invpgah1',
    image : thb12,
  },
  {
    id : 8,
    type: 'video',
    booth: "Glucerna",
    link : '527624629',
    image : thb14,
  },
  {
    id : 9,
    booth: "Ensure Gold",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/nfn03nahb2t65mj786xoudw74kzu',
    image : thb7,
  },
  {
    id : 10,
    booth: "Ensure Gold",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/r4shus845vop7ls4fdx77v4t7eh5',
    image : thb8,
  },
  {
    id : 11,
    booth: "Specialized Nutrition",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/sgwj5r98jyu7x1v7vvypfjmgx2r3',
    image : thb9,
  },
  {
    id : 12,
    booth: "Specialized Nutrition",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/z9zltzwmfe5cpfqtw9nds2op1tmq',
    image : thb10,
  },
  {
    id : 13,
    booth: "Specialized Nutrition",
    link : 'https://kfair20.s3.ap-southeast-1.amazonaws.com/b9wguahac52ch0bu6qusxhg2wn5f',
    image : thb11,
  },
  {
    id : 14,
    type: "video",
    link : '533058281',
    image : playback,
  },
  {
    id : 15,
    type: "video",
    link : '533056401',
    image : playback,
  },
]