
const getButtonPos = (boothType) => {
  switch (boothType){
    case 1:
      return {
        vid: `
          top: 44.5%;
          left: 66%;
        `,
        image: `
          top: 55%;
          left: 50%;
        `,
        chat: `
          top: 68.5%;
          left: 66%;
        `,
        pdf: `
          top: 41%;
          left: 90%;
        `,
        info: `
          top: 9%;
          left: 44%;
        `,
        namecard: `
          top: 67%;
          left: 77%;
        `,
      }
    case 2:
      return {
        // vid: `
        //   top: 50%;
        //   left: 31.5%;
        // `,
        image: `
          top: 40%;
          left: 45%;
        `,
        chat: `
          top: 82%;
          left: 16%;
        `,
        pdf: `
          top: 35%;
          left: 84.5%;
        `,
        info: `
          top: 0%;
          left: 82%;
        `,
        namecard: `
          top: 60%;
          left: 15%;
        `,
      }
    case 3:
      return {
        vid: `
          top: 36%;
          left: 67.5%;
        `,
        image: `
          top: 20%;
          left: 22%;
        `,
        chat: `
          top: 79.5%;
          left: 80%;
        `,
        pdf: `
          top: 58%;
          left: 60%;
        `,
        info: `
          top: 1%;
          left: 88%;
        `,
        namecard: `
          top: 78%;
          left: 67%;
        `,
      }
    case 4:
      return {
        vid: `
          top: 46%;
          left: 69%;
        `,
        image: `
          top: 50%;
          left: 35%;
        `,
        chat: `
          top: 71.5%;
          left: 66%;
        `,
        pdf: `
          top: 69%;
          left: 51%;
        `,
        info: `
          top: 18%;
          left: 63%;
        `,
        namecard: `
          top: 82.5%;
          left: 72.5%;
        `,
        zoom: `
          top: 82.5%;
          left: 72.5%;
        `,
      }
    case 5:
      return {
        vid: `
          top: 38%;
          left: 77%;
        `,
        image: `
          top: 60%;
          left: 20%;
        `,
        chat: `
          top: 60%;
          left: 85%;
        `,
        pdf: `
          top: 61%;
          left: 53%;
        `,
        info: `
          top: -1%;
          left: 59%;
        `,
        namecard: `
          top: 78.5%;
          left: 73%;
        `,
      }
    default:
      return {}
  }
}
export { getButtonPos };