import React from 'react'
import { Modal, Carousel } from 'react-bootstrap'
import speaker from '../../images/event/EMC-speaker.jpg'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'
import { LazyLoadComponent } from 'react-lazy-load-image-component';
import config from '../../config';

const StyledModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    box-shadow: 0px 4px 12px ${COLORS.lightGray1} ;
  }
  .modal-body {
    padding: 0;
  }
`
const StyledCarousel = styled(Carousel)`
  .carousel-control-next,
  .carousel-control-prev {
    opacity: 1;
  }

  .carousel-control-prev {
    left: -6%;
  }
  .carousel-control-next {
    right: -6%;
  }
  span.carousel-control-next-icon, span.carousel-control-prev-icon {
    padding: 12px;
    background-color: #E7B119;
    border: 6px solid #E7B119;
    border-radius: 4px;
  }
`

export default function SpeakersModal({ showModal, hideSchedule }) {
  return (
    <StyledModal
      show={showModal}
      onHide={hideSchedule}
      size="lg"
      aria-labelledby="speakers-bio"
      centered
    >
      <Modal.Body>
        <LazyLoadComponent>
          <StyledCarousel>
            {
              config.assets.speakers.map((image, index)=>(
                <Carousel.Item key={`speakerinfo-${index}`} >
                  <img
                    src={image}
                    className="d-block mx-auto"
                    width="100%"
                    alt={`speakerinfo-${index}`}
                  />
                </Carousel.Item>
              ))
            }
          </StyledCarousel>
        </LazyLoadComponent>
      </Modal.Body>
    </StyledModal>
  )
}
