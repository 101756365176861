import React from 'react';
import styled from 'styled-components';
import { useObject } from 'react-firebase-hooks/database'
import { NoticeDb } from '../../firebaseConfig'
import logo from '../../images/logo.png'

const StyledNotice = styled.div`
  background-color: rgba(255, 255, 255, 0.75);
  width: calc(100% - 8px);
  padding: 4px 12px;
  border-radius: 8px;
  position: absolute;
  bottom: 230px;
  margin: 2px 4px;
  .notice-message {
    font-weight: 500;
    color: #002060;
    vertical-align: bottom;
  }
  @media screen and (min-width: 540px) {
    bottom: 275px;
  }
`;

export default function MobileNotice() {
  const [snapshot, loading, error] = useObject(NoticeDb)

  if (loading || error) {
    return <></>
  }
  return (
    <StyledNotice>
      {/* <img src={logo} width="25px" className="mr-1" alt="logo" /> */}
      <small className="text-secondary">Announcement</small>
      <div>
        <span className="notice-message">{snapshot.val()}</span>
      </div>
    </StyledNotice>
  )
}
