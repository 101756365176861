import { firebaseDb } from '../firebaseConfig'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const usePageUserCount = (currentUser) => {
  const location = useLocation()
  const pagePath = location.pathname
  const userCountDb =
    process.env.REACT_APP_STAGE === 'production'
      ? `userCount/${pagePath}/${currentUser.id}`
      : `userCount/local/${pagePath}/${currentUser.id}`
  useEffect(() => {
    firebaseDb.ref(userCountDb).set(new Date().toString())

    return () => {
      firebaseDb.ref(userCountDb).remove()
    }
  }, [pagePath, currentUser.id])

  firebaseDb.ref('.info/connected').on('value', snapshot => {
    if (!snapshot.val()) return

    firebaseDb.ref(userCountDb).onDisconnect().remove()
  })
}


export { usePageUserCount }
