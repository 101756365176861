import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import { COLORS } from '../../styles/theme'

const MobileLanding = (props) => {
  return (
    <Page>
      <Link className='login-button' to={routes.login}>
        <div>ENTER EVENT</div>
        <div className="arrow">⇀</div>
      </Link>
    </Page>
  )
}

const Page = styled.div`
  .login-button {
    display: flex;
    justify-content: space-between;
    width: 65%;
    margin: 2em auto;
    font-size: 1em;
    color: #1A1A1A;
    border: 1px solid #1A1A1A;
    font-style: italic;
    padding: 12px 24px;
    border-radius: 30px;
    letter-spacing: 1px;
    font-weight: 600;
    background: linear-gradient(to right, #F8C723, #E7B119, #FDF382, #FEE15C, #FFDA4C);
    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }

  @media screen and (min-width: 540px) {
    .login-button {
      width: 45%;
      margin: 4em auto;
      font-size: 1.2em;
      padding: 12px 30px;
    }
  }
`

export default MobileLanding
