import { useObject } from 'react-firebase-hooks/database'
import { useDispatch } from 'react-redux'
import { kickUser } from '../ducks/auth'
import { firebaseDb } from '../firebaseConfig'

function handleDeviceSession(uid) {
  const sessionToken = new Date().toString()
  const sessionRef =
    process.env.REACT_APP_STAGE === 'production'
      ? `session/${uid}`
      : `session/local/${uid}`
  firebaseDb.ref(sessionRef).set(sessionToken)
  localStorage.setItem('session_token', sessionToken)
}

function useDuplicateSession(currentUser) {
  const dispatch = useDispatch()
  const sessionRef =
    process.env.REACT_APP_STAGE === 'production'
      ? `session/${currentUser?.id}`
      : `session/local/${currentUser?.id}`

  const [snapshot, loading, error] = useObject(firebaseDb.ref(sessionRef))

  if (!loading && !error && snapshot.val() && snapshot.val() !== localStorage.getItem('session_token')) {
    dispatch(kickUser())
  }
}

export { handleDeviceSession, useDuplicateSession }
