import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyDhh9NnHAhaaj0JgQmMN8KakErBqtddJU0",
  authDomain: "abbott-tns-2.firebaseapp.com",
  databaseURL: "https://abbott-tns-2-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "abbott-tns-2",
  storageBucket: "abbott-tns-2.appspot.com",
  messagingSenderId: "230033605667",
  appId: "1:230033605667:web:9e84178222dafc77975d96"
};

firebase.initializeApp(firebaseConfig)

const firebaseDb = firebase.database()
const firebaseAuth = firebase.auth()

const ChatDb = firebaseDb.ref('chat')
const ChatKfairDb = firebaseDb.ref('chatAuditorium')
const ChatMcDb = firebaseDb.ref('chatMc')
const NoticeDb = firebaseDb.ref('notice')
const AccessDb = firebaseDb.ref('access')
const NotificationDb = firebaseDb.ref('pushNotifications')

export {
  firebaseDb,
  firebaseAuth,
  ChatDb,
  ChatKfairDb,
  ChatMcDb,
  NoticeDb,
  NotificationDb,
  AccessDb,
}
