import Axios from 'axios'
import React, { useState } from 'react'
import { Modal, Form, Button } from 'react-bootstrap'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import config from '../../config'
import { COLORS } from '../../styles/theme'
import Amplitude from '../../utils/amplitudeHelper'

const CustomModal = styled(Modal)`
  .modal-content {
    border: 2px solid ${COLORS.primaryLight};
    border-radius: 28px;
    box-shadow: 0px 4px 12px ${COLORS.lightGray1};
    padding: 16px;
  }
  .modal-header {
    border: none;
    padding: 0 16px;
  }
  .modal-body {
    padding: 0 16px;
  }
  .btn {
    display: block;
    width: 100%;
    margin-left: auto;
    border: none;
    border-radius: 28px;
    padding: 6px 16px;
    background: ${COLORS.primaryLight};
  }
  textarea {
    border-radius: 10px;
    padding: 10px 15px;
    border: solid 2px #707070;
  }
`

export default function BoothNamecardModal({ currentUser, booth, modal, hideModal }) {
  const [message, setMessage] = useState('')
  const [load, setLoad] = useState(false)

  const handleInput = (e) => setMessage(e.target.value)

  const submitNamecard = () => {
    setLoad(true)
    Amplitude.dropNamecard({
      senderId: currentUser.id,
      senderFullName: currentUser.fullName,
      senderEmail: currentUser.email,
      receiverFullName: booth.title,
      receiverEmail: booth.email,
    })
    Axios({
      method: 'post',
      url: `${config.apiUrl}/api/v1/booths/drop_namecard`,
      data: {
        receiver_email: booth.email,
        message,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
      },
    })
      .then(() => {
        toast(`An email has been sent to ${booth.title}!`)
      })
      .catch((err) => {
        setLoad(false)
        setMessage('')
        toast('Sending name card failed. Something went wrong.');
      })
      .finally(() => {
        setLoad(false)
        hideModal(0)
        setMessage('')
      })
  }

  return (
    <CustomModal
      show={modal}
      onHide={hideModal}
      aria-labelledby="drop-name-card"
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <p>
          Drop <strong>{booth.title}</strong> your name card!
        </p>
        <p>
          This will send {booth.title} an email with your contact information
          indicating that you wish to connect.
        </p>
        <p>You can also include custom message:</p>
        <Form.Control
          placeholder="(Optional)"
          value={message}
          onChange={handleInput}
          as="textarea"
          rows={3}
        />
        <Button disabled={load} onClick={submitNamecard} className="mt-2">
          {load ? 'Sending...' : 'Drop your name card'}
        </Button>
      </Modal.Body>
    </CustomModal>
  )
}
