import React from 'react'
import styled from 'styled-components'
import routes from '../routes'
import { firebaseDb } from '../firebaseConfig'
import { useList } from 'react-firebase-hooks/database'
import LoadIcon from '../components/LoadIcon'

const Page = styled.div`
  background: #2e3188;
  min-height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  padding: 50px;

  #board {
    background-color: white;
    border-radius: 24px;
    padding: 32px;
    width: 35%;
    h4 {
      text-decoration: underline;
      margin: 8px auto;
    }
    span {
      background-color: red;
      color: white;
      border-radius: 4px;
      padding: 2px 4px;
    }
  }
`

export default function ActiveUserCountPage() {
  const envDbRef = `userCount${process.env.REACT_APP_STAGE === 'production' ? '' : '/local'}`

  const [lobbySnapshots, lobbyLoading, lobbyError] = useList(firebaseDb.ref(envDbRef + routes.lobby))
  const [audSnapshots, audLoading, audError] = useList(firebaseDb.ref(envDbRef + routes.auditorium1))
  const [exSnapshots, exLoading, exError] = useList(firebaseDb.ref(envDbRef + routes.exhibition1))
  const [gallerySnapshots, galleryLoading, galleryError] = useList(firebaseDb.ref(envDbRef + routes.gallerium))
  const [networkSnapshots, networkLoading, networkError] = useList(firebaseDb.ref(envDbRef + routes.networking))
  const [booth1Snapshots, booth1Loading, booth1Error] = useList(firebaseDb.ref(envDbRef + '/booths/1'))
  const [booth2Snapshots, booth2Loading, booth2Error] = useList(firebaseDb.ref(envDbRef + '/booths/2'))
  const [booth3Snapshots, booth3Loading, booth3Error] = useList(firebaseDb.ref(envDbRef + '/booths/3'))
  const [booth4Snapshots, booth4Loading, booth4Error] = useList(firebaseDb.ref(envDbRef + '/booths/4'))

  
  if (lobbyLoading || audLoading || exLoading || galleryLoading || networkLoading){
    return <LoadIcon />
  }
  return (
    <Page>
      <div id="board">
        <h4>Active Users In Hall</h4>
        <h5>Lobby : <span>{lobbySnapshots.length}</span></h5>
        <h5>Auditorium : <span>{audSnapshots.length}</span></h5>
        <h5>Networking : <span>{networkSnapshots.length}</span></h5>
        <h5>Exhibition : <span>{exSnapshots.length}</span></h5>
        <h5>Booth1 : <span>{booth1Snapshots.length}</span></h5>
        <h5>Booth2 : <span>{booth2Snapshots.length}</span></h5>
        <h5>Booth3 : <span>{booth3Snapshots.length}</span></h5>
        <h5>Booth4 : <span>{booth4Snapshots.length}</span></h5>
        <h5>Gallery : <span>{gallerySnapshots.length}</span></h5>
      </div>
    </Page>
  )
}
