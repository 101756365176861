import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import routes from '../../routes'
import config from '../../config'
import materials from '../../constants/galleryList'
import Amplitude from '../../utils/amplitudeHelper'
import { Row, Col } from 'react-bootstrap'
import ModalVideo from 'react-modal-video'

const MobilePosterGallery = ({ currentUser }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Gallery`
  }, [])

  useEffect(() => {
    Amplitude.visit("Gallery")
  }, [currentUser.id])

  const openLink = (item) => {
    Amplitude.clickGalleryItem(item.id)
    if (item.type === 'video') {
      setIsOpen(true)
      setVideoId(item.link)
    } else {
      window.open(
        item.link,
        '_blank',
      )
    }
  }
  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="title-container">
            <p className="title">Gallery</p>
            <Link className="back-button" to={routes.lobby}>
              Back
            </Link>
          </div>

          <h4 className="category-title">Event Playback (Day 1 - 3rd April 2021)</h4>
          <Row noGutters className="justify-content-between">
            <Col xs={6} className="list-item">
              <img
                alt="gallery item"
                src={materials.slice(13,14)[0].image}
                className="list-image"
                onClick={() => openLink(materials.slice(13,14)[0])}
              />
            </Col>
          </Row>

          <h4 className="category-title">Event Playback (Day 2 - 4th April 2021)</h4>
          <Row noGutters className="justify-content-between">
            <Col xs={6} className="list-item">
              <img
                alt="gallery item"
                src={materials[materials.length-1].image}
                className="list-image"
                onClick={() => openLink(materials[materials.length-1])}
              />
            </Col>
          </Row>

          <h4 className="category-title">Ensure Gold</h4>
          <Row noGutters className="justify-content-between">
            {materials.slice(8,10).map((item, index) => (
              <Col xs={6} className="list-item" key={index}>
                <img
                  alt="gallery item"
                  src={item.image}
                  className="list-image"
                  onClick={() => openLink(item)}
                />
              </Col>
            ))}
          </Row>
          <h4 className="category-title">Specialized Nutrition</h4>
          <Row noGutters className="justify-content-between">
            {materials.slice(10,materials.length-1).map((item, index) => (
              <Col xs={6} className="list-item" key={index}>
                <img
                  alt="gallery item"
                  src={item.image}
                  className="list-image"
                  onClick={() => openLink(item)}
                />
              </Col>
            ))}
          </Row>

          <h4 className="category-title">Ensure Max Protein</h4>
          <Row noGutters className="justify-content-between">
            {materials.slice(0,2).map((item, index) => (
              <Col xs={6} className="list-item" key={index}>
                <img
                  alt="gallery item"
                  src={item.image}
                  className="list-image"
                  onClick={() => openLink(item)}
                />
              </Col>
            ))}
          </Row>
          <h4 className="category-title">Glucerna</h4>
          <Row noGutters className="justify-content-between">
            {materials.slice(2,8).map((item, index) => (
              <Col xs={6} className="list-item" key={index}>
                <img
                  alt="gallery item"
                  src={item.image}
                  className="list-image"
                  onClick={() => openLink(item)}
                />
              </Col>
            ))}
          </Row>
          
        </div>
      </div>
      {
        isOpen &&
        <ModalVideo
          channel="vimeo"
          autoplay
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => setIsOpen(false)}
        />
      }
    </Page>
  )
}

const Page = styled.div`
  background: white;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
  }

  .container {
    width: 90%;
    margin: auto;
  }
  h4.category-title {
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 12px;
    margin-left: 8px;
  }
  .title-container {
    margin: 30px 0px 10px 0px;
    height: 100px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .title {
    width: 80%;
    font-size: 22px;
    font-weight: bold;
    font-style: italic;
    color: black;
    margin: 0;
  }

  .back-button {
    font-size: 12px;
    color: #312384;
    font-weight: bold;
    margin-top: 10px;
  }

  .list-item {
    width: 100%;
    /* height: 125px; */
    margin-bottom: 32px;
  }

  .list-image {
    width: 90%;
    display: block;
    margin: 0 auto;
    height: auto;
    margin-bottom: 20px;
  }

  .image-zoom {
    width: 100%;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobilePosterGallery
