import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LobbyOverlay from '../components/LobbyOverlay'
import LobbyEntry from '../components/LobbyEntry'
import GameModal from '../components/Gamification/GameModal'
import config from '../config'
import { toast } from 'react-toastify'

const PageWrapper = styled.div`
  .trailer {
    background-color: black;
    position: absolute;
    top: 23%;
    left: 41%;
    right: 41%;
    bottom: 56.7%;
  }

  .standing-banner {
    cursor: pointer;
    position: fixed;
    top: 70%;
    left: 5%;
    right: 96%;
    height: 20%;
    animation: bounce 3s infinite;
  }
`


export default function LobbyPage({ currentUser }) {
  const [overlayTitle, setOverlayTitle] = useState('Select hall to enter:')
  const [overlay, setOverlay] = useState(null)

  const showEntryOverlay = (destinations) => {
    setOverlay(destinations)
  }

  const hideOverlay = () => setOverlay(null)

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      hideOverlay()
    }
  }, [])

  const showHelpDesk = () => {
    // toast("Event has ended.")
    const cpt = window.chaport
    cpt.on('ready', function () {
      cpt.setVisitorData({
        name: currentUser.fullName,
        email: currentUser.email,
      })
      cpt.open()
    })
    cpt.on('collapsed', function () {
      cpt.close()
    })
    Amplitude.clickHelpDesk()
  }

  useEffect(() => {
    document.title = `${config.eventName} | Lobby`
  }, [])

  useEffect(() => {
    Amplitude.visit("Lobby")
  }, [currentUser.id])

  const [showLobbyModal, setShowLobbyModal] = useState(false);
  const toggleLobbyModal = () => setShowLobbyModal(!showLobbyModal);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false)

    return () => {
      document.removeEventListener('keydown', escFunction, false)
    }
  }, [escFunction])

  return (
    <PageWrapper>
      <PageWithBg bgImg={config.assets.lobby.landscape}>
        <LobbyOverlay
          overlayTitle={overlayTitle}
          overlay={overlay}
          hideOverlay={hideOverlay}
        />
        {config.lobbyEntries.map((entry) => {
          return (
            <LobbyEntry
              showHelpDesk={showHelpDesk}
              key={entry.id}
              setOverlayTitle={setOverlayTitle}
              showEntryOverlay={showEntryOverlay}
              {...entry}
            />
          )
        })}
        <div className="trailer">
          <iframe
            width="100%"
            height="100%"
            title="Event Trailer"
            src="https://player.vimeo.com/video/631610906?h=be9888a100&autoplay=1&loop=1"
            allow="autoplay; fullscreen; picture-in-picture"
            allowFullScreen
            frameBorder="0"
          />
        </div>
        {showLobbyModal && <GameModal showModal={showLobbyModal} hideModal={toggleLobbyModal} currentUser={currentUser} />}
      </PageWithBg>
    </PageWrapper>
  )
}
