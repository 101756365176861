import React from 'react'
import styled from 'styled-components'
import { SIZES } from '../../styles/theme'

const NavWrapper = styled.nav`
  padding: 12px 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  small.tab-items {
    margin: 0;
    font-weight: 600;
    cursor: pointer;
  }
  .active {
    color: #00B1FF;
    text-decoration: underline;
  }
  ${SIZES.mobile} {
    border-radius: 0px;
    text-align: center;
    small.tab-items {
      font-size: 11px;
      font-weight: 500;
    }
  }
`

function GameNav({ tab, setTab }) {
  const navTabs = [
    { id: 'nav-1', text: "How To Play"},
    { id: 'nav-2', text: "Mission Tasks"},
    { id: 'nav-3', text: "Winner List"},
    { id: 'nav-4', text: "Terms & Conditions"},
  ]
  return (
    <NavWrapper>
      {navTabs.map(nav => 
        <small key={nav.id} className={`${tab === nav.text ? 'active' : ''} tab-items`} onClick={()=>setTab(nav.text)}>{nav.text}</small>
      )}
    </NavWrapper>
  )
}

export { GameNav }