import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import { CoinComponent } from './CoinComponent'
import { Link } from 'react-router-dom'
import routes from '../../routes'

const StyledModal = styled(Modal)`
  animation: appear 0s 3s forwards;
  .modal-content {
    margin: 0 auto;
    border: none;
    border-radius: 28px;
    background: rgba(255, 255, 255, 1);
    text-align: center;
    border: 2px solid rgb(0, 92, 208);
  }
  .modal-header {
    border: none;
    
    .close {
      color: #F83636;
      opacity: 1;
      padding: 0 16px;
      font-size: 40px;
      font-weight: 400;
      text-shadow: none;
    }
  }

  .modal-body {
    /* padding: 18px 12px; */
  }
  h5, strong {
    color: rgb(0, 92, 208);
    font-weight: 600;
    margin-bottom: 4px;
  }
  p {
    font-size: 15px;
    margin-bottom: 4px;
  }
  a {
    color: #492777;
  }

`

function MobileCoinModal ({ mission, coinModal, setCoinModal }) {
  return (
    <StyledModal backdrop={false} show={coinModal} onHide={()=>setCoinModal(false)} aria-labelledby="collect-coin-modal" size="lg" >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <CoinComponent mission={mission} />
        <h5>Good Work!</h5>
        <p><strong>You’ve found a token worth 10 points.</strong></p>
        <p>Click on the token above to collect your points.</p>
        <Link to={routes.lucky}>See My Score</Link>
      </Modal.Body>
    </StyledModal>

  )
}
export { MobileCoinModal }
