import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import { useParams, useHistory } from 'react-router-dom'
import { LazyLoadImage, LazyLoadComponent } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import { loadTawk } from '../utils/tawkToHelper'
import { getBooth, getBooths } from '../ducks/booth'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import DownloadModal from '../components/GlobalModals/DownloadModal'
import IframeModal from '../components/GlobalModals/IframeModal'
import PosterModal from '../components/GlobalModals/PosterModal'
import InfoModal from '../components/GlobalModals/InfoModal'
import VideoModal from '../components/GlobalModals/VideoModal'
import TawkModal from '../components/GlobalModals/TawkModal'
import chatIcon from '../images/booth/chat.png'
import playIcon from '../images/booth/video.png'
import photoIcon from '../images/booth/viewer.png'
import pdfIcon from '../images/booth/pdf.png'
import infoIcon from '../images/booth/companyinfo.png'
import boothBg from '../images/backdrops/boothBg.jpg'
import BoothNamecardModal from '../components/GlobalModals/BoothNamecardModal'
import config from '../config'
import { HintComponent } from '../components/Gamification/HintComponent'
import { formatMissionType, pointerButton } from '../utils/missionHelper'
import hintPointer from '../images/gamification/finger.png'

const Page = styled.div`
  overflow: hidden;
  #button-container {
    position: absolute;
    bottom: 4vh;
    left: 2%;
    .hot-buttons {
      width: 125px;
    }
  }
  .hot-buttons,
  .hot-actions {
    background: linear-gradient(to right, #F8C723, #E7B119, #FDF382, #FEE15C, #FFDA4C);
    border-radius: 40px;
    border: 2px solid #ffad2c;
    padding: 8px 16px;
    color: #1A1A1A;
    margin: 0 8px;
    &:hover {
      box-shadow: 0px 3px 12px #1A1A1A;
    }
    small {
      margin: 0;
      display: block;
      font-weight: 500;
      font-size: 70%;
      font-weight: 500;
    }
    svg {
      transition: 500ms;
      width: 25px;
      height: 25px;
    }
  }
  .booth-container {
    position: fixed;
    left: 50%;
    width: 67vw;
    min-width: 950px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -55%);
    .hot-actions {
      border-radius: 50%;
      padding: 0;
      img,
      svg {
        transition: 500ms;
        width: 28px;
        height: 28px;
      }
      small {
        transition: 500ms;
        font-size: 1px;
        opacity: 0;
        color: white;
        display: none;
      }
      &:hover {
        box-shadow: 0px 3px 12px #ffad2c;
        small {
          font-size: 8px;
          opacity: 1;
          display: block;
        }
        small.extra-small {
          font-size: 6px;
        }
        img,
        svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
  #tawk-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 40vw;
    height: 40vh;
    z-index: 9999;
  }
  .exhibitor-title {
    text-align: right;
    width: 40vw;
    position: fixed;
    bottom: 3%;
    right: 5%;
    font-weight: 600;
  }
`
const HotButton = styled.div`
  position: absolute;
  ${(props) => props.css}
  cursor: pointer;
  img {
    width: 65px;
    height: 65px;
    transition: 200ms;
  }
  &:hover {
    img {
      transform: scale(1.25);
    }
  }
`

const EMBED_DIV_ID = 'TAWK_EMBED'

const btnPos = {
  vid: `
    top: 44.5%;
    left: 66%;
  `,
  image: `
    top: 24%;
    left: 26%;
  `,
  chat: `
    top: 68.5%;
    left: 66%;
  `,
  pdf: `
    top: 55%;
    left: 84%;
  `,
  info: `
    top: 9%;
    left: 44%;
  `,
  namecard: `
    top: 67%;
    left: 77%;
  `,
}

const Pointer = styled.div`
  position: absolute;
  width: 150px;
  height: 150px;
  ${(props) => props.css}
  .circle {
    border-radius: 50%;
    border: 2px dashed #F85D49;
    height: 100%;
    width: 100%;
    transform: translate(-40%, -40%);
    opacity: 0;
    animation: appear 1s forwards;
  }
  .finger-pointer {
    position: absolute;
    z-index: 10;
    left: 30%;
    bottom: 60%;
    // left: 60%;
    // bottom: 15%;
    animation: appear 2s 3s forwards, mousepoint 1s 3s infinite;
    animation-iteration-count: 1;
    width: 50px;
  }
`

export default function BoothPage({ currentUser }) {
  const [showBtn, setShowBtn] = useState(false)
  const [isOpen, setOpen] = useState(false)
  const [posterOpen, setPosterOpen] = useState(false)
  const [downloadOpen, setDownloadOpen] = useState(false)
  const [infoOpen, setInfoOpen] = useState(false)
  const [videoOpen, setVideoOpen] = useState(false)
  const [tawkOpen, setTawkOpen] = useState(false)
  const [nameCardModal, setNameCardModal] = useState(false)
  const [playVideo, setPlayVideo] = useState({})
  const [missionName, setMissionName] = useState("")
  const [showMission, setShowMission] = useState(false)

  const dispatch = useDispatch()
  const { viewBooth, booths } = useSelector((state) => state.booth)
  const { boothId } = useParams()
  const history = useHistory()

  useEffect(() => {
    dispatch(getBooth(boothId))
  }, [boothId, dispatch])

  useEffect(() => {
    if (viewBooth && viewBooth.id == boothId) {
      dispatch(getBooths(viewBooth.hall.id))
      Amplitude.visitBooth(viewBooth.title)
    }
  }, [boothId, currentUser.id, viewBooth])

  const handleModal = (video) => {
    Amplitude.playBoothVideo(viewBooth.title, video)
    setPlayVideo({
      title: 'Video',
      link: video,
    })
    setOpen(true)
    setVideoOpen(false)
  }

  const handleDownload = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'pdf')
    setDownloadOpen(!downloadOpen)
  }

  const handleVideoModal = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'video')
    if (viewBooth.booth_videos.length > 1) {
      setVideoOpen(!videoOpen)
    } else {
      Amplitude.playBoothVideo(viewBooth.title, viewBooth.booth_videos[0].url)
      setPlayVideo({
        title: 'Video',
        link: viewBooth.booth_videos[0].url,
      })
      setOpen(true)
      setVideoOpen(false)
    }
  }

  const handlePoster = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'poster')
    setPosterOpen(!posterOpen)
  }
  const handleInfo = () => {
    Amplitude.clickHotButtons(viewBooth.title, 'info')
    setInfoOpen(!infoOpen)
  }

  const openTawk = () => {
    if (viewBooth) {
      Amplitude.clickHotButtons(viewBooth.title, 'chat')
      const { chat_link } = viewBooth
      loadTawk(currentUser, chat_link, EMBED_DIV_ID)
      setTawkOpen(!tawkOpen)
    }
  }

  const navigatePrev = () => {
    setShowBtn(false)
    // const navigateTo = booths.filter(booth=>booth.order===viewBooth.order-1)[0].id
    // history.push(`/booths/${navigateTo}`)
    const index = booths.findIndex(booth => booth.id === viewBooth.id);
    let target = index - 1;
    if (target < 0) target = booths.length - 1;

    history.push(`/booths/${booths[target].id}`);
  }
  const navigateNext = () => {
    setShowBtn(false)
    // const navigateTo = booths.filter(booth=>booth.order===viewBooth.order+1)[0].id
    // history.push(`/booths/${navigateTo}`)
    const index = booths.findIndex(booth => booth.id === viewBooth.id);
    let target = index + 1;
    if (target >= booths.length) target = 0;

    history.push(`/booths/${booths[target].id}`);
  }

  const handleShowHint = () => {
    Amplitude.clickHint(viewBooth.title)
    // setMissionName(formatMissionType(viewBooth.mission.mission_type))
    setMissionName('test')
    setShowMission(true)
  }

  return (
    <Page>
      <PageWithBg bgImg={boothBg}>
        <HintComponent showMission={showMission} handleShowHint={handleShowHint} missionName={'Show'} />
        {!showBtn && <LoadIcon />}
        {viewBooth && (
          <LazyLoadComponent>
            <div className="booth-container">
              <LazyLoadImage
                effect="black-and-white"
                afterLoad={() => setShowBtn(true)}
                className="booth-img"
                src={config.assets.boothBackdrops[viewBooth.id - 1]}
                width="100%"
                alt="booth image"
              />
              {showBtn && (
                <>
                {showMission &&
                  viewBooth.missions.map(m => (
                    <Pointer css={btnPos[pointerButton(m.mission_type)]}>
                      <img className="finger-pointer" src={hintPointer} alt="pointer" />
                      {showMission && <div className="circle"></div>}
                    </Pointer>
                  ))
                }
                {
                  btnPos.vid &&
                  <HotButton
                    css={btnPos.vid}
                    onClick={handleVideoModal}
                  >
                    <img src={playIcon} width="26" alt="Nav" />
                  </HotButton>
                }
                  <HotButton
                    css={btnPos.pdf}
                    onClick={handleDownload}
                  >
                    <img src={pdfIcon} width="26" alt="Nav" />
                  </HotButton>
                  <HotButton
                    css={btnPos.image}
                    onClick={handlePoster}
                  >
                    <img src={photoIcon} width="26" alt="Nav" />
                  </HotButton>
                  <HotButton
                    css={btnPos.info}
                    onClick={handleInfo}
                  >
                    <img src={infoIcon} width="26" alt="Nav" />
                  </HotButton>
                  <HotButton
                    css={btnPos.chat}
                    onClick={openTawk}
                  >
                    <img src={chatIcon} width="26" alt="Nav" />
                  </HotButton>
                </>
              )}
            </div>
            <div id="button-container">
              <button
                className="hot-buttons"
                onClick={() =>
                  history.push(`/exhibition-hall-${viewBooth.hall.id}`)
                }
              >
                <svg width="53.514" height="21.333" viewBox="0 0 53.514 21.333">
                  <path d="M30,8a27.905,27.905,0,0,0-18.4,6.933L6.56,9.893A2.667,2.667,0,0,0,2,11.76V26.667a2.675,2.675,0,0,0,2.667,2.667H19.573a2.675,2.675,0,0,0,1.893-4.56L16.373,19.68A21.236,21.236,0,0,1,49.2,26.667a3.279,3.279,0,0,0,4,1.707,3.346,3.346,0,0,0,2-4.587A28.094,28.094,0,0,0,30,8Z" transform="translate(-2 -8)" fill="#1A1A1A"/>
                </svg>
                <small>Return to Hall</small>
              </button>
              {viewBooth.order !== 0 && (
                <button className="hot-buttons" onClick={navigatePrev}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <g transform="translate(17.301 26.839) rotate(180)">
                      <path d="M.459,14.462,13.147,26.407a1.637,1.637,0,0,0,2.216,0l1.48-1.393a1.415,1.415,0,0,0,0-2.084L6.79,13.419,16.845,3.909a1.415,1.415,0,0,0,0-2.084L15.363.432a1.637,1.637,0,0,0-2.216,0L.459,12.376A1.415,1.415,0,0,0,.459,14.462Z" transform="translate(17.301 26.839) rotate(180)" fill="#1A1A1A"/>
                    </g>
                  </svg>
                  <small>Previous Booth</small>
                </button>
              )}
              {viewBooth.order !== booths.length-1 && (
                <button className="hot-buttons" onClick={navigateNext}>
                  <svg width="17.301" height="26.839" viewBox="0 0 17.301 26.839">
                    <path d="M.459,12.376,13.147.432a1.637,1.637,0,0,1,2.216,0l1.48,1.393a1.415,1.415,0,0,1,0,2.084L6.79,13.419,16.845,22.93a1.415,1.415,0,0,1,0,2.084l-1.48,1.393a1.637,1.637,0,0,1-2.216,0L.459,14.462A1.415,1.415,0,0,1,.459,12.376Z" transform="translate(17.301 26.839) rotate(180)" fill="#1A1A1A"/>
                  </svg>
                  <small>Next Booth</small>
                </button>
              )}
            </div>
            {/* <h4 className="exhibitor-title">{viewBooth.title}</h4> */}
            <VideoModal
              videos={viewBooth.booth_videos}
              setVideoOpen={setVideoOpen}
              videoOpen={videoOpen}
              handleModal={handleModal}
            />
            {
              isOpen &&
              <IframeModal
                iframeOpen={isOpen}
                setIframeOpen={setOpen}
                iframe={playVideo}
                mission={viewBooth.missions.find(m => m.mission_type == "watch_video")}
              />
            }
            <DownloadModal
              files={viewBooth.documents}
              setDownloadOpen={setDownloadOpen}
              downloadOpen={downloadOpen}
              mission={viewBooth.missions.find(m => m.mission_type == "download_pdf")}
            />
            <PosterModal
              images={viewBooth.wallpaper_gallery}
              posterOpen={posterOpen}
              setPosterOpen={setPosterOpen}
              mission={viewBooth.missions.find(m => m.mission_type == "image_gallery")}
            />
            <InfoModal
              title={viewBooth.title}
              description={viewBooth.description}
              content={viewBooth.content}
              agents={viewBooth.booth_agents}
              infoOpen={infoOpen}
              setInfoOpen={setInfoOpen}
              mission={viewBooth.missions.find(m => m.mission_type == "check_company_info")}
            />
            <TawkModal
              embedId={EMBED_DIV_ID}
              tawkOpen={tawkOpen}
              setTawkOpen={setTawkOpen}
            />
            <BoothNamecardModal 
              currentUser={currentUser} 
              hideModal={()=>setNameCardModal(false)} 
              modal={nameCardModal} 
              booth={viewBooth} 
            />
          </LazyLoadComponent>
        )}
      </PageWithBg>
    </Page>
  )
}
