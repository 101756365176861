import React from 'react';
import { Switch, Route } from 'react-router-dom';
import styled from 'styled-components';
import routes from '../../routes';
import config from '../../config';
import MobileLanding from './MobileLanding';
import MobileLogin from './MobileLogin';
import MobileForgotPassword from './MobileForgotPassword';
import logo from "../../images/registration/bannerL.png"

const MobileEntrance = () => {
  return (
    <Page>
      <div id="content">
        {/* <img id="logo" className="mx-auto d-block" src={logo} alt="logo" /> */}
        <div id="logo" />
        
        <Switch>
          <Route exact path={routes.root}>
            <MobileLanding />
          </Route>

          <Route exact path={routes.login}>
            <MobileLogin />
          </Route>

          <Route exact path={routes.forgotPassword}>
            <MobileForgotPassword />
          </Route>

        </Switch>
      </div>
    </Page>
  );
}

const Page = styled.div`
  background: url(${config.assets.register.portrait});
  background-size: cover;
  background-position: top;
  height: 100%;
  min-height: 100vh;
  width: 100vw;
  padding-top: 1rem;
  
  #content {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    #logo {
      width: 90%;
      height: 334px;
    }
  }
  @media screen and (min-width: 540px) {
    #content {
      #logo {
        width: 70%;
      }
    }
  }
`

export default MobileEntrance;