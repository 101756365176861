import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { Row, Col } from 'react-bootstrap'
import config from '../config'
import { COLORS, SIZES } from '../styles/theme'
import { ChatDb } from '../firebaseConfig'
import LoadIcon from '../components/LoadIcon'
import ChatCard from '../components/Networking/ChatCard'
import UserDirectory from '../components/Networking/UserDirectory'
import ContactCard from '../components/Networking/ContactCard'
import useChat from '../utils/chatHelper'
import back from '../images/icons/back.png'
import Amplitude from '../utils/amplitudeHelper'

const Page = styled.div`
  padding-top: ${SIZES.navHeight};
  height: 100vh;
  background-size: cover;
  background-image: url(${config.assets.networking.landscape});
  background-repeat: no-repeat;
  text-align: center;
  overflow: hidden;

  #content {
    border-radius: 4px;
    position: absolute;
    top: ${SIZES.navHeight};
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 90%;

    .row-wrapper {
      height: 100%;

      [class^='col-'] {
        height: 100%;
      }
    }
  }

  #refresh-btn {
    border: none;
    background: white;
    padding: 5px 10px;

    &:hover {
      border: solid 1px ${COLORS.primary};
    }
  }
  .chat-header {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 32px 24px;
    header {
      padding-bottom: 4px;
      margin-bottom: 8px;
      h6 {
        font-weight: 600;
        text-align: left;
        color: ${COLORS.primary};
      }
      .contact-list {
        align-self: flex-end;
        cursor: pointer;
        margin: 0;
        line-height: 30px;
        vertical-align: middle;
        border: none;
        outline: none;
        background: transparent;
        color: #312782;
        span {
          font-size: 14px;
          font-weight: bold;
        }
        #icon {
          transform: scaleX(-1)
        }
      }
    }

    .topics {
      display: flex;
      margin: 8px;

      button {
        border-radius: 50%;
        border: none;
        background: transparent;
      }
      .topic-label {
        display: none;
        font-weight: bold;
      }
      .selected {
        margin-left: 0;
        margin-right: auto;
        .topic-label {
          display: inline-block;
        }
      }
    }
    .divider {
      background: linear-gradient(270deg, #fe1e9a, #254dde);
      height: 2px;
    }
  }
`

export default function NetworkingLoungePage({ currentUser }) {
  const {
    messages,
    messagesLoading,
    messagesError,
    handleSubmitMessage,
    submitLoading,
    handleFetchOlderMessages,
    fetchLoading,
    scrollTrigger,
  } = useChat(ChatDb);
  const [viewUser, setViewUser] = useState(null)
  const [showDirectory, setShowDirectory] = useState(false)
  const [users, setUsers] = useState([])

  useEffect(() => {
    document.title = `${config.eventName} | Networking`
  }, [])

  useEffect(() => {
    Amplitude.visit('Networking Lounge')
  }, [currentUser.id])

  // useEffect(() => {
  //   Axios({
  //     url: `${config.apiUrl}/api/v1/users`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     },
  //   }).then((result) => {
  //     setUsers(result.data.users)
  //   })
  // }, [])

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleDirectory = () => {
    setShowDirectory(!showDirectory)
  }

  const toggleAvatar = (avatarId) => {
    // if (users) {
    //   let filtered = users.filter((user) => user.id === avatarId)
    //   setViewUser(filtered[0])
    // }
  }
  return (
    <Page>
      <div id="content">
        <Row noGutters className="row-wrapper">
          <Col sm={3} lg={4}>
            <div className="px-3 h-100">
              
            </div>
          </Col>
          <Col sm={6} lg={4}>
            {
              messagesError ? (
              <button id="refresh-btn" onClick={refreshPage}>
                Connection failed. Click here to refresh.
              </button>
            ) : (
              <div className="chat-header">
                <header className="d-flex justify-content-between align-items-end">
                  <h6>Networking Lounge</h6>
                  <div></div>
                </header>
                <div className="divider"></div>
                <div className="topics">
                  <span className="font-weight-bold">
                    Say hi and join our discussion here
                  </span>
                </div>
                <div className="divider"></div>
                <ChatCard
                  messages={messages}
                  messagesLoading={messagesLoading}
                  handleFetchOlderMessages={handleFetchOlderMessages}
                  fetchLoading={fetchLoading}
                  scrollTrigger={scrollTrigger}
                  handleSubmitMessage={handleSubmitMessage}
                  submitLoading={submitLoading}
                  currentUser={currentUser}
                  toggleAvatar={toggleAvatar}
                />
              </div>
            )}
          </Col>
          <Col sm={3} lg={4}>
            <div className="px-3 h-100">
              {/* {showDirectory && (
                <UserDirectory
                  users={users}
                  setViewUser={setViewUser}
                  toggleDirectory={toggleDirectory}
                />
              )} */}
            </div>
          </Col>
        </Row>
      </div>
    </Page>
  )
}
