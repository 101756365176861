import React, { useState } from 'react'
import styled from 'styled-components'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import PhoneInput from 'react-phone-input-2'

import banner from '../../images/registration/bannerL.png'
import bannerM from '../../images/registration/bannerP.png'

import { COLORS, SIZES } from '../../styles/theme'
import Axios from 'axios'
import config from '../../config'
import { toast } from 'react-toastify'
import routes from '../../routes'

const Page = styled.div`
  min-height: 100vh;
  background-image: url(${config.assets.register.landscape});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  padding-top: 1em;
  padding-right: 1.5em;
  padding-bottom: 4.5em;
  color: white;
  img#banner-portrait {
    display: none;
  }
  ${SIZES.mobile} {
    background-image: url(${config.assets.register.portrait});
    padding: 0;
    padding-bottom: 2.5em;
    padding-top: 1em;
    font-size: 0.9em;
    img#banner-portrait {
      display: block;
    }
    .mobile-hide {
      display: none;
    }
  }
  
  .row {
    ${SIZES.mobile} {
      .col-md-7 {
        flex: none;
        max-width: 100%
      }
    }
    h5.subtitle {
      margin: 24px 0;
      font-weight: bold;
      ${SIZES.mobile} {
        margin: 8px 0;
      }
    }
    .register-form {
      padding: 2.5em 0;
      width: 60%;
      ${SIZES.mobile} {
        width: 100%;
      }
      .form-group {
        width: 100%;
        margin: 10px auto;
        background: #FFFFFF;
        border: none;
        border-radius: 12px;
        font-weight: 600;
        text-align: center;
        padding-right: 16px;
        label {
          color: #00469D;
          padding: 0 8px;
          margin: 0 auto;
          padding-right: 0;
          font-size: 13px;
        }
        .required:before {
          content:"* ";
          color: red;
        }
        input[type='tel'],
        .form-input {
          display: block;
          color: #1FB4F4;
          letter-spacing: 1px;
          width: 100%;
          font-weight: 500;
          background: transparent;
          border: none;
          padding: 10px 16px;
          outline: none;
          ::placeholder {
            color: #1FB4F4;
          }
        }
        .react-tel-input {
          color: #279AAC;
          input[type='tel'] {
            padding: 10px 0;
            padding-left: 56px;
            height: 41px;
            box-shadow: none;
          }
          .country-list {
            margin: 0;
          }
          .flag-dropdown {
            background: transparent;
            border: none;
            padding-left: 16px;
            .selected-flag {
              background: transparent;
            }
          }
        }
      }
      .select-input {
        width: 40%;
        margin-left: 0;
        .form-input {
          padding-left: 10px;
          padding-right: 0px;
        }
      }
      .help-text {
        font-size: 12px;
        font-style: italic;
        font-weight: 400;
      }
    }
  }

  button.submit-btn {
    background: linear-gradient(to right, #F8C723, #E7B119, #FDF382, #FEE15C, #FFDA4C);
    width: 45%;
    color: #1A1A1A;
    font-weight: 600;
    border: 1px solid #1A1A1A;
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    padding: 8px 24px;
    margin: 16px auto;
    margin-right: 0px;
    margin-bottom: 0;
    font-size: 1em;
    outline: none;
    box-shadow: none;

    ${SIZES.mobile} {
      padding: 4px 24px;
    }

    &:disabled {
      cursor: not-allowed;
      background: grey;
      border: solid 1px ${COLORS.primary};
    }

    div {
      line-height: 32px;
    }
    div.arrow {
      line-height: 24px;
      font-size: 1.5em;
      align-self: flex-end;
    }
  }
`

export default function RegisterFormPage() {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [salutation, setSalutation] = useState('Mr')
  const [fullName, setFullName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('60')
  const [placePractice, setPlacePractice] = useState('')
  const [speciality, setSpeciality] = useState('')
  const [state, setState] = useState('')
  const [MMC, setMMC] = useState('')
  const optionList = [
    'Datuk',
    'Dato',
    'Datin',
    'Prof.',
    'Assoc. Prof',
    'Dr',
    'Mr',
    'Ms',
    'Madam',
  ]
  const stateList = [
    'Johor',
    'Kedah',
    'Kelantan',
    'Kuala Lumpur',
    'Melaka',
    'Negeri Sembilan',
    'Pahang',
    'Penang',
    'Perak',
    'Perlis',
    'Sabah',
    'Sarawak',
    'Selangor',
    'Terengganu',
  ]

  const handleEmailInput = (e) => {
    setEmail(e.target.value.toLowerCase())
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    setLoading(true)
    const options = {
      salutation,
      full_name: fullName.trim(),
      email,
      phone,
      place_of_practice: placePractice,
      mmc_number: MMC,
      speciality: speciality,
      state: state,
    }
    Axios.post(`${config.apiUrl}/api/v1/register`, options)
      .then(() => {
        setLoading(false)
        history.push(routes.postRegister)
      })
      .catch((err) => {
        setLoading(false)
        toast(err.response.data.message)
      })
  }

  const hasEmptyField = !(
    salutation.length &&
    fullName.length &&
    email.length &&
    phone.length >= 7 &&
    placePractice.length &&
    state.length &&
    MMC.length &&
    speciality.length
  )

  return (
    <Page>
      <Row id="main" noGutters className="px-4 px-md-5 pt-3 pb-3 pt-md-5">
        <div>
          <img id="banner-portrait" width="100%" src={bannerM} alt="event-banner" className="mx-auto my-2" />
        </div>

        <Col md={5} className="mobile-hide align-self-center">
          <img id="banner" width="80%" src={banner} alt="event-banner" className="mx-auto d-block" />
        </Col>

        <Col md={7} className="px-md-3">
          <h5 className="subtitle">Kindly fill in your details below to complete registration</h5>
          {hasEmptyField && (
            <p className="text-danger m-0">
              * Please fill in the required field to proceed.
            </p>
          )}
          <Form className="register-form py-3">
            <Form.Group className="select-input">
              <select
                className="form-input"
                value={salutation}
                placeholder="Salutation"
                onChange={(e) => setSalutation(e.target.value)}
              >
                <option value="" disabled selected hidden>Salutation</option>
                {optionList.map((item, i) => (
                  <option key={`option-${i}`} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group>
              <input
                className="form-input"
                required
                type="text"
                value={fullName}
                placeholder="Full Name (as per NRIC)"
                onChange={(e) => setFullName(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <input
                className="form-input"
                required
                type="email"
                value={email}
                placeholder="Email Address"
                onChange={handleEmailInput}
              />
            </Form.Group>
            <Form.Group>
              <PhoneInput
                inputProps={{ required: true }}
                className="form-input"
                value={phone}
                placeholder="Mobile Number"
                onChange={(phone) => setPhone(phone)}
              />
            </Form.Group>
            <Form.Group>
              <input
                className="form-input"
                required
                type="text"
                placeholder="Place of Practice"
                value={placePractice}
                onChange={(e) => setPlacePractice(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <input
                className="form-input"
                required
                type="text"
                placeholder="Speciality"
                value={speciality}
                onChange={(e) => setSpeciality(e.target.value)}
              />
            </Form.Group>
            <Form.Group>
              <select
                className="form-input"
                value={state}
                onChange={(e) => setState(e.target.value)}
              >
                <option value="" disabled selected hidden>State</option>
                {stateList.map((item, i) => (
                  <option key={`state-${i}`} value={item}>
                    {item}
                  </option>
                ))}
              </select>
            </Form.Group>
            <Form.Group>
              <input
                className="form-input"
                required
                type="text"
                placeholder="MMC Registration Number"
                value={MMC}
                onChange={(e) => setMMC(e.target.value)}
              />
            </Form.Group>
            <div className="w-100">
              <Button
                type="submit"
                className="submit-btn"
                variant="default"
                disabled={hasEmptyField || loading}
                onClick={handleSubmit}
              >
                <div>{loading ? 'Loading...' : 'SUBMIT'}</div>
                <div className="arrow">⇀</div>
              </Button>
            </div>
          </Form>
            
        </Col>
      </Row>
    </Page>
  )
}
