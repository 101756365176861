import React from 'react'
import styled from 'styled-components'
import intro from '../../images/gamification/intro.jpeg'
import bg from '../../images/gamification/bg2.png'
import banner from '../../images/gamification/event_banner.png'
import { SIZES } from '../../styles/theme'
import title from '../../images/gamification/TOKEN_HUNTER.png'

const Wrapper = styled.div`
  button {
    position: absolute;
    bottom: 5%;
    left: 50%;
    font-weight: 500;
    transform: translateX(-50%);
  }
  .banner {
    display: none;
  }
  #intro {
    border-radius: 12px;
  }
  ${SIZES.mobile} {
    width: 100%;
    min-height: 350px;
    background: url(${bg});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
    #intro {
      display: none;
    }
    .banner {
      display: block;
      opacity: 0;
      margin: 0 auto;
      padding: 18px 8px;
      padding-bottom: 0;
    }
    button.start-button {
      position: static;
      transform: none;
      max-width: 500px;
    }
  }
`

function IntroTab({ setTab }) {
  return (
    <Wrapper>
      <img id="intro" src={intro} width="100%" alt="lucky draw introduction" />
      <img className="banner" src={banner} width="85%" alt="event name" />
      <img className="banner p-0" src={title} width="65%" alt="lucky draw" />
      <button onClick={()=>setTab("How To Play")} className="start-button">START PLAYING</button>
    </Wrapper>
  )
}

export { IntroTab }
