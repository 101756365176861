import LogoL from './images/logo.png'
import Entrance from './images/backdrops/entrance.png'
import EntranceM from './images/backdrops/entranceP.jpg'
import LobbyL from './images/backdrops/lobby.png'
import Exhibition from './images/backdrops/exhibition-hall.png'
import Auditorium from './images/backdrops/auditorium.png'
import AuditoriumM from './images/backdrops/auditoriumP.jpg'
import CountdownL from './images/registration/countdownL.png'
// import CountdownP from './images/registration/countdownP.png'
import CountdownP from './images/registration/countdownP2.png'
import RegisterL from './images/backdrops/registerL.jpg'
import RegisterP from './images/backdrops/registerP2.jpeg'
import MC from './images/backdrops/MC20.png'
import NetworkingL from './images/backdrops/networking.jpg'
import NetworkingP from './images/backdrops/networkingP.jpg'
import LobbyMusic from './images/audio/lobby.mp3'
import routes from './routes'
import Gallerium from './images/backdrops/gallerium.jpg'
import Agenda from './images/event/agenda.png'
import Speaker1 from './images/event/Slide1.jpeg'
import Speaker2 from './images/event/Slide2.jpeg'
import Speaker3 from './images/event/Slide3.jpeg'
import Speaker4 from './images/event/Slide4.jpeg'
import Speaker5 from './images/event/Slide5.jpeg'
import Speaker6 from './images/event/Slide6.jpeg'
import StandingBanner from './images/standing-banner.png'
import BannerP from './images/registration/bannerP2.png'

import EnsureFamilyBoothThumbnail from './images/booth-thumbnails/Ensure-Family.png'
import GlucernaBoothThumbnail from './images/booth-thumbnails/Glucerna.png'
import SpecializedNutritionBoothThumbnail from './images/booth-thumbnails/Specialized-Nutrition.png'

import EnsureFamilyBoothBackdrop from './images/booth-backdrop/Ensure-Family.png'
import GlucernaBoothBackdrop from './images/booth-backdrop/Glucerna.png'
import SpecializedNutritionBoothBackdrop from './images/booth-backdrop/Specialized-Nutrition.png'

const dev = {
  apiUrl: 'http://localhost:3000',
}

const prod = {
  apiUrl: 'https://totalnutritionsolution2.herokuapp.com',
}

const API_KEYS = {
  amplitude: 'aab39c81e7c333b1b2617c81f2516bd6',
}
const eventDays = []

const VOLUME_VALUE = 0.2

const config = process.env.NODE_ENV === 'production' ? prod : dev

const assets = {
  logo: {
    landscape: LogoL,
    portrait: LogoL,
  },
  register: {
    landscape: RegisterL,
    portrait: RegisterP,
  },
  countdown: {
    landscape: CountdownL,
    portrait: CountdownP,
  },
  entrance: {
    landscape: Entrance,
    portrait: EntranceM,
  },
  lobby: {
    landscape: LobbyL,
    portrait: EntranceM,
  },
  auditorium1: {
    landscape: Auditorium,
    portrait: AuditoriumM,
  },
  auditorium2: MC,

  exhibition1: Exhibition,
  exhibition2: Exhibition,

  networking: {
    landscape: NetworkingL,
    portrait: NetworkingP,
  },
  gallerium: Gallerium,

  lobbyMusic: LobbyMusic,

  agenda: Agenda,

  speakers: [Speaker1, Speaker2, Speaker3, Speaker4, Speaker5, Speaker6],

  standingBanner: StandingBanner,

  boothThumbnails: [
    EnsureFamilyBoothThumbnail,
    GlucernaBoothThumbnail,
    SpecializedNutritionBoothThumbnail
  ],

  boothBackdrops: [
    EnsureFamilyBoothBackdrop,
    GlucernaBoothBackdrop,
    SpecializedNutritionBoothBackdrop
  ],

  mainBanner: {
    portrait: BannerP
  }
}

const layout = {
  auditoriumVid: `
    top: 8%;
    bottom: 10.5%;
    left: 4%;
    right: 4.5%;
  `,
}

const lobbyEntries = [
  {
    id: 'auditorium',
    destination: routes.auditorium1,
    css: `
      top: 77%;
      left: 60%;
      right: 29%;
      bottom: 24.5%;
    `,
  },
  // {
  //   id: 'exhibition',
  //   destination: routes.exhibition1,
  //   css: `
  //     top: 77%;
  //     left: 29%;
  //     right: 61%;
  //     bottom: 24.5%;
  //   `,
  // },
  // {
  //   id: 'networking',
  //   destination: routes.networking,
  //   css: `
  //     top: 80%;
  //     left: 59.8%;
  //     right: 34%;
  //     bottom: 20.5%;
  //   `,
  // },
  // {
  //   id: 'gallerium',
  //   destination: routes.gallerium,
  //   css: `
  //     top: 81.5%;
  //     left: 76.4%;
  //     right: 16.5%;
  //     bottom: 19.5%;
  //   `,
  // },
  {
    id: 'helpdesk',
    destination: 0,
    css: `
      top: 89.5%;
      left: 21.5%;
      right: 74.5%;
      bottom: 26.5%;
    `,
  },
]

export default {
  API_KEYS,
  assets,
  layout,
  lobbyEntries,
  eventDays,
  VOLUME_VALUE,
  
  eventName: 'Total Nutrition Solution 2.0 2021',
  // liveUrl: "https://vimeo.com/event/1396167/embed/1cb0425b5d?autoplay=1",
  liveUrl: "https://player.vimeo.com/video/637854858?h=4149a33c3d",

  ...config,
}
