import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Axios from 'axios'
import { ChatDb } from '../../firebaseConfig'
import config from '../../config'
import ChatCard from '../../components/Networking/ChatCard'
import mobileNetworking from '../../images/mobile/mobile-networking.jpg'
import { COLORS } from '../../styles/theme'
import useChat from '../../utils/chatHelper'
import Amplitude from '../../utils/amplitudeHelper'

const MobileNetworking = ({ currentUser }) => {
  const [showDirectory, setShowDirectory] = useState(false)
  const [users, setUsers] = useState([])

  const {
    messages,
    messagesLoading,
    messagesError,
    handleSubmitMessage,
    submitLoading,
    handleFetchOlderMessages,
    fetchLoading,
    scrollTrigger,
  } = useChat(ChatDb);

  // useEffect(() => {
  //   Axios({
  //     url: `${config.apiUrl}/api/v1/users`,
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem('jwt_token')}`,
  //     },
  //   }).then((result) => {
  //     setUsers(result.data.users)
  //   })
  // }, [])

  useEffect(() => {
    Amplitude.visit('Networking Lounge')
  }, [currentUser.id])

  const refreshPage = () => {
    window.location.reload()
  }

  const toggleAvatar = (avatarId) => {
    // if (users) {
    //   let filtered = users.filter((user) => user.id === avatarId)
    //   setViewUser(filtered[0])
    // }
  }

  return (
    <Page>
      <div className="outer-wrapper">
        {messagesError ? (
          <button onClick={refreshPage}>
            Connection failed. Click here to refresh.
          </button>
        ) : (
          <div className="chat-header">
            <header className="d-flex justify-content-between align-items-end">
              <h6>Networking Lounge</h6>
              <div></div>
            </header>
            <div className="divider"></div>
            <div className="topics">
              <span className="font-weight-bold">
                Say hi and join our discussion here
              </span>
            </div>
            <div className="divider"></div>
            <ChatCard
              toggleAvatar={toggleAvatar}
              messages={messages}
              messagesLoading={messagesLoading}
              handleFetchOlderMessages={handleFetchOlderMessages}
              fetchLoading={fetchLoading}
              scrollTrigger={scrollTrigger}
              handleSubmitMessage={handleSubmitMessage}
              submitLoading={submitLoading}
              currentUser={currentUser}
            />
          </div>
        )}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${mobileNetworking});
  background-size: cover;
  background-position: 70% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
    max-width: 540px;
    margin: auto;
    height: 100%;
  }
  .chat-header {
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    padding: 32px 24px;
    header {
      padding-bottom: 4px;
      margin-bottom: 8px;
      h6 {
        font-weight: 600;
        text-align: left;
        color: ${COLORS.primary};
      }
      .contact-list {
        align-self: flex-end;
        cursor: pointer;
        margin: 0 8px;
        line-height: 30px;
        vertical-align: middle;
        border: none;
        outline: none;
        background: transparent;
        color: #312782;
        span {
          font-size: 14px;
          font-weight: bold;
        }
        #icon {
          transform: scaleX(-1)
        }
      }
    }

    .topics {
      display: flex;
      margin: 8px;

      button {
        border-radius: 50%;
        border: none;
        background: transparent;
      }
    }
    .divider {
      background: linear-gradient(270deg, #fe1e9a, #254dde);
      height: 2px;
    }
  }
`

export default MobileNetworking
