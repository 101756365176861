import React from 'react'
import { Modal } from 'react-bootstrap'
import { COLORS } from '../../styles/theme'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    border: solid 2px #5c628a;
    border-radius: 28px;
    padding:8px;
  }
  .modal-header {
    border: none;
  }
  h6 {
    color: ${COLORS.primary};
  }
  .file-container {
    display: flex;
    justify-content: space-between;
    button {
      background: transparent;
      border: none;
      color: ${COLORS.primaryLight};
    }
  }
`

export default function VideoModal({ videos, videoOpen, setVideoOpen, handleModal }) {
  return (
    <StyledModal
      show={videoOpen}
      onHide={()=>setVideoOpen(false)}
      aria-labelledby="video-modal"
      centered
    >
      <Modal.Header closeButton>
        <h6 id="video-modal">Select video(s) to play</h6>
      </Modal.Header>
      <Modal.Body>
        {
          videos.map((video,index)=>(
            <div key={`video-${index}`} className="file-container">
              <p>{ video.filename }</p>
              <button onClick={()=>handleModal(video.url)}>
                <svg width="26" height="26" viewBox="0 0 24 24" fill="none" stroke={COLORS.primaryLight} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <circle cx="12" cy="12" r="10"></circle>
                  <polygon points="10 8 16 12 10 16 10 8"></polygon>
                </svg>
              </button>
            </div>
          ))
        }
      </Modal.Body>
    </StyledModal>
  )
}
