import amplitude from 'amplitude-js'
import config from '../config'

const Amplitude = {
  init: () => {
    amplitude.getInstance().init(config.API_KEYS.amplitude)
  },

  // TODO: Put all amplitude tracking functions here
  visit: (page) => {
    amplitude.getInstance().logEvent(`Visit ${page}`)
  },

  visitExhibition: (hallNumber) => {
    amplitude.getInstance().logEvent(`Visit Exhibition Hall`, {
      hallNumber: hallNumber,
    })
  },

  visitBooth: (boothName) => {
    amplitude.getInstance().logEvent(`Visit Booth`, {
      boothName: boothName,
    })
  },

  clickHelpDesk: () => {
    amplitude.getInstance().logEvent('Click Lobby Helpdesk')
  },

  clickMute: () => {
    amplitude.getInstance().logEvent('Click Mute button');
  },

  clickLogout: () => {
    amplitude.getInstance().logEvent('Click Logout');
  },

  clickMenu: () => {
    amplitude.getInstance().logEvent('Click Menu')
  },

  clickNotification: () => {
    amplitude.getInstance().logEvent('Click Notifications')
  },

  clickAgenda: () => {
    amplitude.getInstance().logEvent('Click Agenda Modal')
  },

  clickSpeaker: () => {
    amplitude.getInstance().logEvent('Click Speaker Modal')
  },

  clickCheckIn: () => {
    amplitude.getInstance().logEvent('Click Checkin List')
  },

  clickGalleryItem: (id) => {
    amplitude.getInstance().logEvent('Click Gallery Item', {
      galleryId: id
    })
  },

  clickHotButtons: (boothName, type) => {
    amplitude.getInstance().logEvent('Click Booth HotButton', {
      boothName: boothName,
      hotButtonType: type,
    })
  },

  // track each booth video
  playBoothVideo: (boothName, video) => {
    amplitude.getInstance().logEvent('Play Booth Video', {
      boothName: boothName,
      video: video,
    })
  },
  
  submitQuestion: (details) => {
    amplitude.getInstance().logEvent('Submit Gsheets Question', {
      UserID: details.id,
      FullName: details.fullName,
      Email: details.email,
      Question: details.question,
      HallName: details.hallName
    });
  },

  uploadImage: () => {
    amplitude.getInstance().logEvent('Upload profile image');
  },

  clickPodcast: (title) => {
    amplitude.getInstance().logEvent(`Click podcast: ${title}`)
  },

  dropNamecard: (details) => {
    amplitude.getInstance().logEvent('Drop name card', {
      SenderID: details.senderId,
      SenderFullName: details.senderFullName,
      SenderEmail: details.senderEmail,
      ReceiverFullName: details.receiverFullName,
      ReceiverEmail: details.receiverEmail,
    });
  },

  collectCoin: (missionId) => {
    amplitude.getInstance().logEvent('Collect Coin', {
      missionId: missionId,
    })
  },

  enterDraw: () => {
    amplitude.getInstance().logEvent('Click Enter Draw')
  },

  clickHint: (boothName) => {
    amplitude.getInstance().logEvent('Click Booth Hint', {
      boothName: boothName,
    })
  },

}

export default Amplitude
