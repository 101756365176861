const formatMissionType = (type) => {
  switch (type) {
    case "watch_video":
      return "Watch & Learn"
    case "check_company_info":
      return "Who's who"
    case "image_gallery":
      return "Clearer Clarity"
    case "download_pdf":
      return "Future Reference"
    default:
      return ""
  }
}

const pointerButton = (type) => {
  switch (type) {
    case "watch_video":
      return "vid"
    case "check_company_info":
      return "info"
    case "image_gallery":
      return "image"
    case "download_pdf":
      return "pdf"
    default:
      return ""
  }
}
const mobilePointer = (type) => {
  switch (type) {
    case "watch_video":
      return `
        top: 50%;
        left: 0%;
      `
    case "check_company_info":
      return `
        top: 50%;
        left: 48%;
      `
    case "image_gallery":
      return  `
        top: 50%;
        left: 30%;
      `
    case "download_pdf":
      return  `
        top: 50%;
        left: 20%;
      `
    default:
      return ""
  }
}


export { formatMissionType, pointerButton, mobilePointer }
