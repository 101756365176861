import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useDispatch } from 'react-redux'
import Amplitude from '../../utils/amplitudeHelper'
import LoadIcon from '../../components/LoadIcon'
import ChatSection from '../../components/ChatSection.js'
import { muteMusic, unmuteMusic } from '../../ducks/layout'
import openTheatre from '../../images/mobile/open-theatre.png'
import closeTheatre from '../../images/mobile/close-theatre.png'
import screen from '../../images/mobile/screen.png'
import { ChatKfairDb, AccessDb } from '../../firebaseConfig'
import config from '../../config'
import routes from '../../routes'
import { toast } from 'react-toastify'
import { useObject } from 'react-firebase-hooks/database'
import { useHistory } from 'react-router-dom'

const MobileKFair20 = ({ currentUser, liveUrl }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [snapshot, loading, error] = useObject(AccessDb)
  const [isLoading, setIsLoading] = useState(true)
  const [theatre, setTheatre] = useState(false)

  useEffect(() => {
    document.title = `${config.eventName} | Conference`
  }, [])
  
  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant' ) {
        toast('Conference Hall is not available now.', {
          toastId: 'eventToast',
        })
        history.push(routes.lobby)
      } else {
        Amplitude.visit('Auditorium')
      }
    }
  }, [loading, snapshot, currentUser.id])

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  const videoContainerTheatreStyle = {
    width: '100%',
    height: '100%',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    background: 'black',
    zIndex: '99',
  }

  if (loading || error) {
    return <LoadIcon />
  }
  return (
    <Page>
      <div className="outer-wrapper">
        <div className="container">
          <div className="screen-container">
            <img src={screen} className="screen" alt="screen" />
            <div
              className="video-container"
              style={theatre ? videoContainerTheatreStyle : {}}
            >
              {isLoading && <LoadIcon />}
              <iframe
                title="Live Broadcast"
                src={liveUrl}
                allow="autoplay; fullscreen"
                allowFullScreen
                frameBorder="0"
                onLoad={() => {
                  setIsLoading(false)
                }}
              />
            </div>
          </div>

          <button
            type="button"
            className="theatre-button"
            onClick={() => setTheatre(true)}
          >
            <img src={openTheatre} className="theatre-button-icon" alt="theatre-icon" />
            <p className="theatre-button-label">Theatre mode</p>
          </button>

        </div>

        <div className="chat-container">
          {/* <ChatSection */}
          {/*   currentUser={currentUser} */}
          {/*   firebaseDb={ChatKfairDb} */}
          {/*   hallName="MainAuditorium" */}
          {/* /> */}
        </div>

        {theatre && (
          <div className="theatre-close">
            <img
              src={closeTheatre}
              onClick={() => setTheatre(false)}
              className="theatre-close-icon"
              alt="close-icon"
            />
          </div>
        )}
      </div>
    </Page>
  )
}

const Page = styled.div`
  background: url(${config.assets.register.portrait});
  background-size: cover;
  background-position: 50% 50%;
  height: calc(100% - 60px);
  width: 100vw;
  overflow-y: scroll;
  border-radius: 0px 0px 25px 25px;
  position: absolute;

  .outer-wrapper {
    width: 100%;
    padding-top: 3rem;
  }

  .container {
    width: 95%;
    margin: auto;
  }

  .checkin-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 30px 0px 20px 0px;
  }

  .checkin {
    width: 38%;
    height: auto;
  }

  .screen-container {
    width: 100%;
    height: 0;
    padding-bottom: 58.5%;
    position: relative;
  }

  .screen {
    width: 100%;
    position: absolute;
  }

  .video-container {
    width: 92%;
    height: 81%;
    background: #1e1e1e;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50.5%, -51%);

    iframe {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    width: 100%;
    margin: 20px 0px;
  }

  .chat-container {
    width: 100%;
    height: 500px;
    margin-top: 48px;
  }

  .theatre-button {
    width: 140px;
    height: 30px;
    background: black;
    margin: 10px 10px 0px 0px;
    float: right;
    border: none;
    border-radius: 5px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0.6;
    padding: 0;
  }

  .theatre-button-label {
    color: white;
    font-size: 12px;
    margin: 0px 10px 0px 0px;
  }

  .theatre-button-icon {
    width: 20px;
    margin: 0px 10px;
  }

  .theatre-close {
    color: white;
    background: black;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 99;
    opacity: 0.6;
  }

  .theatre-close-icon {
    width: 20px;
  }

  @media screen and (min-width: 540px) {
    .outer-wrapper {
      width: 540px;
      margin: auto;
    }
  }
`

export default MobileKFair20
