import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { enterDraw, getMissions, getWinners } from '../../ducks/mission'
import styled from 'styled-components'
import HeaderBanner from '../../images/gamification/header2.png'
import { MissionTaskTab } from './MissionTaskTab'
import { WinnersTab } from './WinnersTab'
import { EntryResultTab } from './EntryResultTab'
import { TermsTab } from './TermsTab'
import { InstructionTab } from './InstructionTab'
import { GameNav } from './GameNav'
import { PointSection } from './PointSection'
import { IntroTab } from './IntroTab'
import Amplitude from '../../utils/amplitudeHelper'

const StyledModal = styled(Modal)`
  .modal-content {
    width: 90%;
    margin: 0 auto;
    border: none;
    border-radius: 28px;
    background: rgba(255, 255, 255, 0.8);
  }
  #banner {
    display: block;
    margin-top: -3.5em;
    margin-left: auto;
    margin-right: 0;
  }
  .modal-header {
    border: none;
    width: 90%;
    margin: 0 auto;

    img {
      width: 100%;
    }
    
    .close {
      color: rgb(0, 92, 208);
      opacity: 1;
      padding: 16px;
      margin-left: 0;
      font-size: 40px;
      font-weight: 400;
      text-shadow: none;
    }
  }
  .modal-body {
    width: 90%;
    margin: 0 auto;
    padding-top: 0;
    padding-left: 24px;
  }
    
  #content-container {
    // border: 2px solid black;
    background: rgba(255, 255, 255, 1);
    border-radius: 12px;
    margin: 16px 0;
    min-height: 350px;
    position: relative;
    color: #022136;
    
    .start-button {
      background: rgb(0, 181, 255);
      color: #fff;
      border: none;
      border-bottom: 4px solid rgb(0, 92, 208);
      box-shadow: 0px 2px -2px rgb(0, 92, 208);
      border-radius: 12px;
      display: block;
      width: 90%;
      margin: 0 auto;
      padding: 6px 0;
    }
  }
  
`

export default function GameModal({ currentUser, showModal, hideModal }) {
  const dispatch = useDispatch()
  const { drawEntered, missions, winners } = useSelector((state) => state.mission)
  const [tab, setTab] = useState(null)

  useEffect(() => {
    dispatch(getMissions())
    dispatch(getWinners())
  }, [])

  useEffect(() => {
    Amplitude.visit('Game')
  }, [currentUser.id])

  const handleEnterDraw = () => {
    Amplitude.enterDraw()
    dispatch(enterDraw(currentUser.id))
    setTab("entry-result-tab")
  }

  return (
    <StyledModal backdrop={false} show={showModal} onHide={hideModal} aria-labelledby="game-modal" size="lg" centered >
      <Modal.Header closeButton>
        <img id="banner" src={HeaderBanner} width="90%" alt="lucky draw banner" />
      </Modal.Header>
      <Modal.Body>
       
        <PointSection handleEnterDraw={handleEnterDraw} />

        <GameNav tab={tab} setTab={setTab} />

        <section id="content-container">
          {!tab && <IntroTab setTab={setTab} />}

          { tab === "How To Play" && <InstructionTab setTab={setTab} />}

          { tab === "Mission Tasks" && <MissionTaskTab missions={missions} />}

          { tab === "Winner List" && <WinnersTab winners={winners} />}

          { tab === "Terms & Conditions" && <TermsTab />}

          { tab === "entry-result-tab" &&  <EntryResultTab drawEntered={drawEntered} setTab={setTab} />}
          
        </section>
      </Modal.Body>
    </StyledModal>
  )
}
