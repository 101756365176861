import React from 'react'
import { SIZES } from '../../styles/theme'
import styled from 'styled-components'

const TermsWrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;
  h6 {
    color: rgb(0, 92, 208);
    font-weight: 600;
  }
  h6, p {
    width: 90%;
    margin: 10px auto;
  }
  p {
    display: flex;
    span {
      margin-left: 8px;
    }
  }
  ${SIZES.mobile} {
    min-height: 350px;
    max-height: inherit;
    p {
      font-size: 80%;
      span {
        margin-left: 6px;
      }
    }
  }
`

function TermsTab() {
  return (
    <TermsWrapper>
      <h6>Terms and Conditions</h6>
      <p>1.
        <span>
           These terms and conditions together with any specific rules set out in Paticipation Notices (as defined below) are the Paticipation Term ("Terms"), together to be defined as the "Mini-Game", unless otherwise expressly stated. By entering this Mini-Game, entrants agree to be bound by these Terms.
           The Mini-Game is organised and operated by MCP (Medical Conference Partners), its agents, partners, representatives or any company or website owned or controlled (directly or indirectly)("us", “we”, ”our” “MCP”).
        </span>
      </p>
      <p>2.
        <span>
          We reserve the right to cancel or amend the Mini-Game, the Participation Notice or these Terms at any time without prior notice. Any changes will be posted either within a Participation Notice or these Terms.
        </span>
      </p>
      <p>3.
        <span>
          In the event of any dispute regarding conduct, results and all other matters relating to the Mini-Game, our decision will be final, and no correspondence or discussion shall be entered into.
        </span>
      </p>
      <p>4.
        <span>
          It is agreed and accepted by you that MCP shall have no liability whatsoever in respect to lucky draw operated on behalf of third parties.
        </span>
      </p>
      <p>5.
        <span>
          Members of MCP or any company involved in the Competition or, if relevant, any advertising agency connected with us, or any such person's subsidiary or associated companies, agents or members of their families or households, are not eligible to enter the Competition. We reserve the right to verify the eligibility of all entrants.
        </span>
      </p>
      <p>6.
        <span>
          We reserve the right to disqualify any entrant if we have reasonable grounds to believe the entrant has significantly breached any of these terms and conditions.
          In the event if any entrant is disqualified from the Competition, in our sole discretion we may decide whether a replacement contestant should be selected. In this event, any further entrant will be selected on the same criteria as the original entrant and will be subject to these Terms.
        </span>
      </p>
    </TermsWrapper>
  )
}

export { TermsTab }
