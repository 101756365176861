import React from 'react'
import styled from 'styled-components'
import activePoint from '../../images/gamification/active-point2.png'
import disabledPoint from '../../images/gamification/disabled-point.png'
import { formatMissionType } from '../../utils/missionHelper'
import { Link } from 'react-router-dom'
import { SIZES } from '../../styles/theme'

const MissionTask = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 12px;
  color: #022136;
  font-size: 90%;
  max-height: 350px;
  overflow-y: scroll;

  .mission-item {
    width: 32%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px 12px;
  }
  a,button {
    padding: 0;
    background: transparent;
    border: none; 
    border-radius: 50%;
    
    &:hover {
      g.arrow {
        fill: #ffcc5a;
      }
    }
  }
  svg {
    width: 80%;
    g.arrow {
      fill: rgb(0, 92, 208);
    }
  }
  p.item-title {
    color: rgb(0, 92, 208);
    font-weight: 600;
    font-size: 11px;
  }
  small {
    font-weight: 500;
  }
  .disabled {
    p.item-title, small {
      color: #414141;
    }
    a svg g.arrow {
      fill: #414141;
    }
  }
  ${SIZES.mobile} {
    .mission-item {
      width: 80%;
      margin: auto;
      max-width: 450px;
    }
  }
`

function MissionTaskTab({ missions }) {
  return (
    <MissionTask>
      {missions.map(mission => (
        <div className={`mission-item ${mission.completed ? 'disabled' : ''}`} key={mission.id}>
          <div className="w-75">
            <p className="item-title m-0 mb-1">{formatMissionType(mission.mission_type)}</p>
            <img src={mission.completed ? disabledPoint : activePoint} width="28px" alt="point" />
            <small> + {mission.points} points</small>
          </div>
          <Link to={`/booths/${mission.booth_id}`}>
            <svg width="58" height="58" viewBox="0 0 58 58">
              <g transform="translate(-9617 -1262)">
                <g className="arrow" transform="translate(9617 1262)" stroke="#fff" strokeWidth="3">
                  <circle cx="29" cy="29" r="29" stroke="none"/>
                  <circle cx="29" cy="29" r="27.5" fill="none"/>
                </g>
                <g transform="translate(9666.925 1269.945) rotate(90)">
                  <path d="M20.924,0A20.924,20.924,0,1,1,0,20.924,20.924,20.924,0,0,1,20.924,0Z" transform="translate(0)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                  <path d="M0,28.4V0" transform="translate(20.924 11.957)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                  <path d="M0,10.462,10.462,0,20.924,10.462" transform="translate(10.462 10.462)" fill="none" stroke="#fff" strokeLinecap="round" strokeWidth="5"/>
                </g>
              </g>
            </svg>
          </Link>
        </div>
      ))}
    </MissionTask>
  )
}
export { MissionTaskTab }
