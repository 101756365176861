export default {
  root: '/',
  
  register: '/registration',
  postRegister: '/registration/complete',
  forgotPassword: '/registration/forgot-password',
  resetPassword: '/registration/create-password',


  login: '/login',

  notifications: '/notifications',
  menu: '/menu',


  lobby: '/lobby',

  networking: '/networking-lounge',
  cloudflix: '/highlights',

  auditorium1: '/auditorium-hall',
  auditorium2: '/mc20-auditorium',

  exhibition1: '/exhibition-hall-1',
  // exhibition2: '/exhibition-hall-2',
  lucky: '/lucky-draw',

  gallerium: '/gallery',
}
