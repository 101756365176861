import React, { useMemo, useEffect } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import Media from 'react-media'

import config from './config'
import routes from './routes'
import { SIZES } from './styles/theme'
import EntrancePages from './containers/EntrancePages'
import PrivateRoute from './containers/PrivateRoute'

import CountdownPage from './pages/CountdownPage'
import RegisterFormPage from './pages/registration/RegisterFormPage'
import PostRegisterPage from './pages/registration/PostRegisterPage'
import ActiveUserCountPage from './pages/ActiveUserCountPage'

import LobbyPage from './webOnlyPages/LobbyPage'
import AuditoriumPage from './webOnlyPages/AuditoriumPage'
import NetworkingLoungePage from './webOnlyPages/NetworkingLoungePage'
import ExhibitionPage from './webOnlyPages/ExhibitionPage'
import BoothPage from './webOnlyPages/BoothPage'
import PosterGalleryPage from './webOnlyPages/PosterGalleryPage'

import MobileEntrance from './mobileOnlyPages/MobileEntrance/index.js'
import MobileExpoBooth from './mobileOnlyPages/MobileExpo/MobileExpoBooth.js'
import MobileExpoDirectory from './mobileOnlyPages/MobileExpo/MobileExpoDirectory.js'
import { MobileGamification } from './mobileOnlyPages/MobileGamification'
import MobileGallerium from './mobileOnlyPages/MobileGallerium/index.js'
import MobileKFair20 from './mobileOnlyPages/MobileKFair20/index.js'
import MobileLobby from './mobileOnlyPages/MobileLobby/index.js'
import MobileMenu from './mobileOnlyPages/MobileMenu/index.js'
import MobileNetworking from './mobileOnlyPages/MobileNetworking/index.js'
import MobileNotifications from './mobileOnlyPages/MobileNotifications/index.js'
import SetPasswordFormPage from './pages/registration/SetPasswordFormPage'

import styled from 'styled-components'

const EventCode = styled.span`
  position: absolute;
  color: black;
  right: 1%;
  bottom: 1px;
  z-index: 99;
`

function App() {
  useEffect(() => {
    document.title = config.eventName
  }, [])

  useEffect(() => {
    window.chaport.q('hideLauncher')
  }, [])

  return useMemo(() => {
    return (
      <>
        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) => !matches && <EventCode id="event-code">MAL.2021.19483.AND.1.</EventCode>}
        </Media>
        <Switch>
          {/* <Route exact path={routes.register} component={RegisterFormPage} /> */}
          {/* <Route exact path={routes.postRegister} component={PostRegisterPage} /> */}
          <Route exact path="/register" render={() => (window.location = "https://registration.totalnutritionsolution2.cloudexpo.my")} />
          {/* <Route exact path={routes.root} component={CountdownPage} /> */}
          <Route exact path={routes.resetPassword} component={SetPasswordFormPage} />

          <Route exact path="/realtime" component={ActiveUserCountPage} />
        </Switch>

        <Media query={`(max-width: ${SIZES.mobileBreakpoint})`}>
          {(matches) =>
            matches ? (
              <Switch>
                <Route exact path={routes.root} component={MobileEntrance} />
                <Route path={routes.login} component={MobileEntrance} />
                <Route exact path={routes.forgotPassword} component={MobileEntrance} />
                <PrivateRoute mobileNav path={routes.lobby} component={MobileLobby} />


                {/* KFair20 */}
                <PrivateRoute
                  mobileNav
                  path={routes.auditorium1}
                  component={(props) => (
                    <MobileKFair20
                      {...props}
                      hallNumber={1}
                      liveUrl={config.liveUrl}
                    />
                  )}
                />

                <PrivateRoute mobileNav path={routes.networking} component={MobileNetworking} />

                <PrivateRoute path={routes.lucky} component={MobileGamification} mobileNav />

                {/* <PrivateRoute */}
                {/*   mobileNav */}
                {/*   path={routes.exhibition1} */}
                {/*   component={(props) => ( */}
                {/*     <MobileExpoDirectory */}
                {/*       {...props} */}
                {/*       hallNumber={1} */}
                {/*     /> */}
                {/*   )} */}
                {/* /> */}

                <PrivateRoute mobileNav path={routes.gallerium} component={MobileGallerium} />
                <PrivateRoute mobileNav path="/booths/:boothId" component={MobileExpoBooth} />
                <PrivateRoute mobileNav path={routes.menu} component={MobileMenu} />
                <PrivateRoute mobileNav path={routes.notifications} component={MobileNotifications} />

              </Switch>
            ) : (
              <Switch>

                <Route exact path={routes.login} component={EntrancePages} />
                <Route exact path={routes.root} component={EntrancePages} />

                <PrivateRoute fixedNav path={routes.lobby} component={LobbyPage} />
                <PrivateRoute fixedNav path={routes.networking} component={NetworkingLoungePage} />

                {/* Auditorium (Vimeo) Hall */}
                <PrivateRoute
                  fixedNav
                  path={routes.auditorium1}
                  component={(props) => (
                    <AuditoriumPage
                      {...props}
                      hallNumber={1}
                      hallBg={config.assets.auditorium1.landscape}
                      liveUrl={config.liveUrl}
                    />
                  )}
                />

                {/* <PrivateRoute */}
                {/*   fixedNav */}
                {/*   path={routes.exhibition1} */}
                {/*   component={(props) => ( */}
                {/*     <ExhibitionPage */}
                {/*       {...props} */}
                {/*       hallNumber={1} */}
                {/*       hallBg={config.assets.exhibition1} */}
                {/*     /> */}
                {/*   )} */}
                {/* /> */}
               
                <PrivateRoute fixedNav path={routes.gallerium} component={PosterGalleryPage} />
                <PrivateRoute fixedNav path="/booths/:boothId" component={BoothPage} />

              </Switch>
            )
          }
        </Media>
      </>
    )
  }, [])
}

export default App
