import React, { useEffect } from 'react'
import styled from 'styled-components'
import amplitude from 'amplitude-js'
import { Link } from 'react-router-dom'
import config from '../config'
import banner from '../images/backdrops/event-board.png'
import { useDispatch, useSelector } from 'react-redux'
import { getBooths } from '../ducks/booth'
import { Row, Col } from 'react-bootstrap'
import Amplitude from '../utils/amplitudeHelper'

const Page = styled.div`
  overflow: hidden;
  background-image: ${(props)=> `url(${props.hallBg})`};
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  height: 100vh;
  padding-top: 30px;

  #banner {
    background-color: black;
    position: absolute;
    top: 5%;
    left: 40%;
    right: 40.5%;
    bottom: 74.7%;
  }

  #hall {
    position: fixed;
    padding: 150px 100px;
    padding-bottom: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    .row {
      width: 80%;
    }
  }
  .booths {
    display: block;
    margin: 20px auto;
    height: 29vh;
    -webkit-transition: margin 0.2s ease-out;
    -moz-transition: margin 0.2s ease-out;
    -o-transition: margin 0.2s ease-out;

    &:hover {
      cursor:pointer;
      margin-top: 5px;
    }
  }
`

export default function ExhibitionPage({
  hallBg,
  hallNumber,
  currentUser,
}) {

  const dispatch = useDispatch()
  const { booths } = useSelector((state) => state.booth)
  const boothPerRow = 3

  useEffect(() => {
    dispatch(getBooths(hallNumber))
  }, [hallNumber, dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Exhibition Hall`
  }, [])

  useEffect(() => {
    Amplitude.visit("Exhibition Hall")
  }, [currentUser.id])

  return (
    <Page hallBg={hallBg} >
      <div id="banner">
        <iframe
          width="100%"
          height="100%"
          title="Event Trailer"
          src="https://player.vimeo.com/video/631610906?h=be9888a100&autoplay=1&loop=1"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
          frameBorder="0"
        />
      </div>
      <div id="hall">
        <Row noGutters>
          {booths.map((booth,index) => {
            return (
              <>
                {
                  (index!==0 && index % boothPerRow === 0)  &&
                  <>
                    <Col md={2}></Col>
                    <Col md={2}></Col>
                  </>
                }
                <Col md={4}>
                  <Link to={`/booths/${booth.id}`}>
                    <img className="booths" src={config.assets.boothThumbnails[index]} alt="booth" />
                  </Link>
                </Col>
              </>
            )
          })}
        </Row>
      </div>
    </Page>
  )
}
