import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import Amplitude from '../utils/amplitudeHelper'
import PageWithBg from '../components/PageWithBg'
import LoadIcon from '../components/LoadIcon'
import { useDispatch } from 'react-redux'
import { showGlobalModal, muteMusic, unmuteMusic } from '../ducks/layout'
import config from '../config'
import { SIZES } from '../styles/theme'
import ChatSection from '../components/ChatSection'
import screen from '../images/backdrops/screen.png'
import { useHistory } from 'react-router-dom'
import routes from '../routes'
import { ChatKfairDb, AccessDb } from '../firebaseConfig'
import { toast } from 'react-toastify'
import { useObject } from 'react-firebase-hooks/database'

const Page = styled.div`
  overflow: hidden;
  .content {
    ${config.layout.auditoriumVid}
    position: absolute;
    background: black;

    iframe {
      height: 100%;
      width: 100%;
    }
  }
  .screen-container {
    position: fixed;
    top: 8%;
    bottom: 33%;
    left: 20%;
    right: 34%;
  }
  .buttons-container {
    position: fixed;
    left: 20%;
    right: 34%;
    top: 70%;
    bottom: 23%;
    display: flex;
    justify-content: space-around;
    align-items: center;

    button {
      background: rgb(2,0,36);
      background: linear-gradient(180deg, rgba(7,74,223,1) 0%, rgba(26,148,226,1) 53%, rgba(39,213,232,1) 93%);
      color: white;
      border: 1px solid white;
      border-radius: 2em;
      padding: 0.5em 3em;
    }
  }
  #screen {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .chat {
    max-width: 350px;
    position: fixed;
    padding-top: ${SIZES.navHeight};
    top: 0;
    left: 67%;
    bottom: 0;
    right: 5%;
    z-index: 30;
    background: rgba(255, 255, 255, 0.4) 0% 0% no-repeat padding-box;
  }
  .attendance {
    position: fixed;
    top: 10%;
    left: 5%;
    display: flex;
    animation: heartbeat 5s infinite;

    .action-label {
      color: white;
      font-weight: 500;
      margin: 0 16px;
      font-style: italic;
      align-self: center;
    }
    button {
      background: transparent;
      border: none;
      padding: 0;
      margin: 0 auto;
      img {
        border-radius: 50%;
      }
    }
    &:hover {
      .action-label,
      img {
        transition: 500ms;
        transform: scale(1.2);
      }
      img {
        box-shadow: 0px 0px 30px 6px #ffad2c;
      }
    }
  }
  
`

export default function AuditoriumPage({
  liveUrl,
  hallBg,
  hallNumber,
  currentUser,
}) {
  const [isLoading, setIsLoading] = useState(true)
  const [snapshot, loading, error] = useObject(AccessDb)
  const history = useHistory()
  const dispatch = useDispatch()

  const displayAgenda = () => {
    Amplitude.clickAgenda()
    dispatch(showGlobalModal('agenda'))
  }

  const displaySpeakers = () => {
    Amplitude.clickSpeaker()
    dispatch(showGlobalModal('speaker'))
  }

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  useEffect(() => {
    document.title = `${config.eventName} | Conference`
  }, [])

  useEffect(() => {
    if (!loading) {
      if (!snapshot.val() && currentUser.role === 'participant') {
        toast('Conference Hall is not available now.', {
          toastId: 'eventToast',
        })
        history.push(routes.lobby)
      } else {
        Amplitude.visit('Auditorium')
      }
    }
  }, [loading, snapshot, currentUser.id])


  if (loading || error) {
    return <LoadIcon />
  }
  return (
    <Page>
      <PageWithBg bgImg={hallBg}>
        <div className="screen-container">
          <img id="screen" src={screen} alt="screen" />
          <div className="content">
            {isLoading && <LoadIcon />}
            <iframe
              title="Live Broadcast"
              src={liveUrl}
              allow="autoplay; fullscreen"
              allowFullScreen
              frameBorder="0"
              onLoad={() => {
                setIsLoading(false)
              }}
            />
          </div>
        </div>
        <div className="chat h-100">
        </div>
        <div className="buttons-container">
          <button onClick={displayAgenda}>Agenda</button>
          <button onClick={displaySpeakers}>Our Speakers</button>
        </div>
      </PageWithBg>
    </Page>
  )
}
