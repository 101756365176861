import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import config from '../config'
import routes from '../routes'
import { SIZES } from '../styles/theme'
import { Link } from 'react-router-dom'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import banner from '../images/registration/bannerP.png'
import PageWithBg from '../components/PageWithBg'
import backIcon from '../images/icons/back.png'
import materials from '../constants/galleryList'
import Amplitude from '../utils/amplitudeHelper'
import ModalVideo from 'react-modal-video'

const Page = styled.div`
  .full {
    position: absolute;
    padding-top: ${SIZES.navHeight};
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;

    .sidenavs {
      height: 73%;
      width: 30%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      color: white;
    }
    .main {
      width: 75%;
      background-color: rgba(255, 255, 255, 0.7);
      padding: 32px 56px;
      padding-right: 72px;
      height: 100%;
      overflow-y: scroll;

      .listing {
        .infographic {
          background: lightgray;
          width: 175px;
          height: 175px;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-right: 16px;
          margin-bottom: 32px;
          cursor: pointer;
        }
      }
      h4.category-title {
        margin-bottom: 12px;
        margin-top: 36px;
        font-weight: 600;
        letter-spacing: 1px;
        text-decoration: underline;
      }
      .toggle-back {
        font-weight: bold;
        color: #171717;
        background: transparent;
        border: none;
        display: flex;
        align-items: center;
        text-decoration: none;
        .back-prev {
          display: flex;
          width: 30px;
          flex-direction: column;
          transition: 1s;
          img {
            width: 26px;
          }
          .icon-label {
            text-align: left;
            font-size: 1px;
            opacity: 0;
          }
          &:hover {
            img {
              transform: scale(0.6);
            }
            .icon-label {
              font-size: 8px;
              opacity: 1;
            }
          }
        }

        span {
          font-size: 24px;
          font-style: italic;
          margin-left: 1em;
        }
      }
    }
  }
`

export default function PosterGalleryPage({ currentUser }) {
  const [isOpen, setIsOpen] = useState(false)
  const [videoId, setVideoId] = useState(null)

  useEffect(() => {
    document.title = `${config.eventName} | Gallery`
  }, [])

  useEffect(() => {
    Amplitude.visit("Gallery")
  }, [currentUser.id])

  const openLink = (item) => {
    Amplitude.clickGalleryItem(item.id)
    if (item.type === 'video') {
      setIsOpen(true)
      setVideoId(item.link)
    } else {
      window.open(
        item.link,
        '_blank',
      )
    }
  }
 
  return (
    <Page>
      <PageWithBg bgImg={config.assets.register.landscape}>
        <section className="full">
          <div className="sidenavs">
            <img
              src={banner}
              className="d-block mx-auto mb-3"
              width="100%"
              alt="event banner"
            />
          </div>
          <div className="main">
            <Link to={routes.lobby} className="toggle-back">
              <div className="back-prev">
                <img src={backIcon} alt="back-icon" />
                <small className="icon-label">Back</small>
              </div>
              <span>Gallery</span>
            </Link>
            <h4 className="category-title">Event Playback (Day 1 - 3rd April 2021)</h4>
            <div className="d-flex flex-wrap listing">
              <LazyLoadComponent>
                <div
                  className="infographic"
                  onClick={() => openLink(materials.slice(13,14)[0])}
                >
                  <img
                    src={materials.slice(13,14)[0].image}
                    width="100%"
                    alt='playback'
                  />
                </div>
              </LazyLoadComponent>
            </div>
            <h4 className="category-title">Event Playback (Day 2 - 4th April 2021)</h4>
            <div className="d-flex flex-wrap listing">
              <LazyLoadComponent>
                <div
                  className="infographic"
                  onClick={() => openLink(materials[materials.length-1])}
                >
                  <img
                    src={materials[materials.length-1].image}
                    width="100%"
                    alt='playback'
                  />
                </div>
              </LazyLoadComponent>
            </div>
            <h4 className="category-title">Ensure Gold</h4>
            <div className="d-flex flex-wrap listing">
              {materials.slice(8,10).map((item, index) =>
                <LazyLoadComponent key={`poster-${index}`}>
                  <div
                    className="infographic"
                    onClick={() => openLink(item)}
                  >
                    <img
                      src={item.image}
                      width="100%"
                      alt={`poster-${index}`}
                    />
                  </div>
                </LazyLoadComponent>
              )}
            </div>
            <h4 className="category-title">Specialized Nutrition</h4>
            <div className="d-flex flex-wrap listing">
              {materials.slice(10,13).map((item, index) =>
                <LazyLoadComponent key={`poster-${index}`}>
                  <div
                    className="infographic"
                    onClick={() => openLink(item)}
                  >
                    <img
                      src={item.image}
                      width="100%"
                      alt={`poster-${index}`}
                    />
                  </div>
                </LazyLoadComponent>
              )}
            </div>
            
            <h4 className="category-title">Ensure Max Protein</h4>
            <div className="d-flex flex-wrap listing">
              {materials.slice(0,2).map((item, index) =>
                <LazyLoadComponent key={`poster-${index}`}>
                  <div
                    className="infographic"
                    onClick={() => openLink(item)}
                  >
                    <img
                      src={item.image}
                      width="100%"
                      alt={`poster-${index}`}
                    />
                  </div>
                </LazyLoadComponent>
              )}
            </div>
            <h4 className="category-title">Glucerna</h4>
            <div className="d-flex flex-wrap listing">
              {materials.slice(2,8).map((item, index) =>
                <LazyLoadComponent key={`poster-${index}`}>
                  <div
                    className="infographic"
                    onClick={() => openLink(item)}
                  >
                    <img
                      src={item.image}
                      width="100%"
                      alt={`poster-${index}`}
                    />
                  </div>
                </LazyLoadComponent>
              )}
            </div>
            
          </div>
        </section>
      </PageWithBg>
      {
        isOpen &&
        <ModalVideo
          channel="vimeo"
          autoplay
          isOpen={isOpen}
          videoId={videoId}
          onClose={() => setIsOpen(false)}
        />
      }
    </Page>
  )
}
