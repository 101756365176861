import React from 'react'
import styled from 'styled-components'
import token from '../../images/gamification/simple-coin.png'
import { useSelector } from 'react-redux'
import { SIZES } from '../../styles/theme'

const ResultWrapper = styled.section`
  padding: 8px 12px;
  border-radius: 8px;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  img {
    width: 32px;
  }
  p {
    margin: 0;
    padding: 0 12px;
    font-size: 18px;
    color: #022136;
    font-weight: 600;
    display: flex;
    align-items: flex-end;
    span {
      margin: 0 16px;
    }
  }
  strong {
    color: #00B1FF;
    margin-right: 12px;
  }
  #enter-button {
    background: #78D878;
    border: none;
    color: #fff;
    font-size: 10px;
    padding: 4px 14px;
    border-radius: 16px;
  }
  ${SIZES.mobile} {
    padding: 8px;
    width: 98%;
    margin: 8px auto;
    img {
      width: 24px;
    }
    p {
      font-size: 13px;
      span {
        margin: 0 4px;
      }
    }
  }
`

function PointSection({ handleEnterDraw }) {
  const { totalScore, drawEntered } = useSelector((state) => state.mission)
 
  return (
    <ResultWrapper>
      <p>
        <img src={token} alt="lucky draw coin" />
        <span>{totalScore} daily point(s) accumulated</span>
      </p>
      {
        drawEntered ?
        <strong>You're In!</strong> :
        <button id="enter-button" style={{background:`${ totalScore >= 100 ? "#00B1FF" : "#78D878"}`}} onClick={handleEnterDraw}>Enter Lucky Draw</button>
      }
    </ResultWrapper>
  )
}

export { PointSection }