import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'
import LoadIcon from '../LoadIcon'
import { useDispatch } from 'react-redux'
import { muteMusic, unmuteMusic } from '../../ducks/layout'
import { CoinComponent } from '../Gamification/CoinComponent'

const StyledModal = styled(Modal)`
  .modal-content {
    background: transparent;
    border: none;
  }
  .iframe-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    iframe {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
`

export default function IframeModal({ iframeOpen, setIframeOpen, iframe, mission }) {
  const [loading, setLoading] = useState(true)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(muteMusic())

    return () => {
      if (localStorage.getItem('muted') === 'false') {
        dispatch(unmuteMusic())
      }
    }
  }, [dispatch])

  return (
    <StyledModal
      show={iframeOpen}
      onHide={()=>setIframeOpen(false)}
      size="lg"
      aria-labelledby="podcast-modal"
      centered
    > 
      { mission && <CoinComponent mission={mission} />}
      <Modal.Body className="p-0">
        <div className="iframe-container" >
          {loading && <LoadIcon />}
          <iframe
            width="100%"
            height="100%"
            title={iframe.title}
            src={iframe.link}
            allow="autoplay; fullscreen; encrypted-media"
            allowFullScreen
            allowtransparency="true"
            frameBorder="0"
            onLoad={() => {
              setLoading(false)
            }}
          />
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
