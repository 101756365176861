import React, { useState } from 'react'
import styled from 'styled-components'
import { useDispatch, useSelector } from 'react-redux'
import { completeTask } from '../../ducks/mission'
import collectedCoin from '../../images/gamification/coin-collected.png'
import coin from '../../images/gamification/coin-to-collect.png'
import { SIZES } from '../../styles/theme'
import Amplitude from '../../utils/amplitudeHelper'

const Wrapper = styled.div`
  position: absolute;
  animation: appear 1s 3s forwards;
  z-index: 10;
  cursor: pointer;
  ${(props) => props.css}
  #coin {
    animation: bounce 3s infinite;
  }
  ${SIZES.mobile} {
    position: static;
    opacity: 1;
    img {
      margin-top: -20px;
      width: 30%;
    }
  }
`

function CoinComponent ({ mission }) {
  const randomCoinPosition = [
    `top: 25%; left:-15%;`,
    `top: 30%; left:-18%;`,
    `top: 35%; right:-15%;`,
    `top: 45%; left:-20%;`,
    `top: 45%; right:-20%;`,
    `top: 33%; right:-20%;`,
  ]

  const dispatch = useDispatch()
  const [completed, setCompleted] = useState(mission.completed)
  const [positionIndex, setPositionIndex] = useState(Math.floor(Math.random() * randomCoinPosition.length))
  

  const collectCoin = (mission) => {
    Amplitude.collectCoin(mission.id)
    dispatch(completeTask({
      mission_id: mission.id
    }))
    mission.completed = true
    setCompleted(true)
  }

  return (
    <Wrapper css={randomCoinPosition[positionIndex]}>
      {
        completed ?
        <img src={collectedCoin} width="90px" alt="coin" /> :
        <img id="coin" onClick={()=>collectCoin(mission)} src={coin} width="90px" alt="coin" />
      }
    </Wrapper>
  )
}
export { CoinComponent }
