import React from 'react'
import styled from 'styled-components'
import coin from '../../images/gamification/instruction-coin.png'
import sample from '../../images/gamification/instruction-sampleBtn.gif'
import hintL from '../../images/gamification/hint-textL.png'
import hint from '../../images/gamification/hint-text.png'
import { SIZES } from '../../styles/theme'

const Wrapper = styled.div`
  padding: 12px;
  color: #022136;
  font-size: 90%;
  h6 {
    color: rgb(0, 92, 208);
    font-weight: 600;
  }
  h6, p {
    width: 90%;
    margin: 10px auto;
  }
  p {
    display: flex;
    span {
      margin-left: 6px;
    }
  }
  
  #sample-btn {
    position: absolute;
    top: 7%;
    left: 50%;
  }
  #hint {
    position: absolute;
    top: 10%;
    left: 75%;
  }
  #mobile-hint {
    display: none;
  }
  ${SIZES.mobile} {
    h6 {
      text-align: center;
      padding-bottom: 6px;
      border-bottom: 2px solid rgb(0, 92, 208);
    }
    p {
      font-size: 80%;
    }
    #hint {
      display: none;
    }
    #mobile-hint {
      display: inline-block;
    }
    #sample-btn, #mobile-hint {
      margin-top: -20px;
      position: static;
    }
  }
`

function InstructionTab({ setTab }) {
  return (
    <Wrapper>
      <h6>How-To-Play Guide</h6>
      <p>1.<span>Choose a task to complete.</span></p>
      <p>2.<span>At the task page, click on indicated button.</span></p>
      <img id="sample-btn" src={sample} className="ml-5" width="20%" alt="coin sample" />
      <img id="hint" src={hintL} width="20%" alt="hint" />
      <img id="mobile-hint" src={hint} width="30%" alt="hint" />
      <p>3.<span>When the point token appears, click to collect.</span></p>
      <img src={coin} className="ml-5" width="30%" alt="coin sample" />
      <p>4.<span>Complete mission in each booth to win a token.</span></p>
      <p>5.<span>One token equal to 10 points.</span></p>
      <p>6.<span>Collect 100 points to participate in lucky draw</span></p>
      <button onClick={()=>setTab("Mission Tasks")} className="start-button">START PLAYING</button>
    </Wrapper>
  )
}

export { InstructionTab }
